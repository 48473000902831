import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { resetState } from './store/actions/content.actions';
import { subReset } from './store/actions/subscription.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title: string = 'Nifty Works TV';

  constructor(public router: Router, private store: Store) {}

  ngOnInit() {
    this.store.dispatch(resetState());
    this.store.dispatch(subReset());
  }
}