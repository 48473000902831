import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isMobile } from 'src/app/utils/deviceType';

@Component({
  selector: 'app-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss']
})
export class GoogleButtonComponent {
  constructor(private router: Router) { }
  
  async continueWithGoogle() { 
    // Construct the Google OAuth URL
    const clientId = '419842304103-v1gmhgsk22vdqlv09b4840gj3n4pl7n5.apps.googleusercontent.com';
    const redirectUri = encodeURIComponent(window.location.origin + '/google/callback'); // Replace with your callback path
    const scopes = 'profile%20email'; // Scopes you want to request, space replaced with %20 for encoding
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}`;

    // Redirect the user to Google's sign-in page
    window.location.href = url;
  }
}