import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  getSearchParameterByName,
  handleUserAuthError,
} from '../utils/httpHandlers';
import { LOCAL_STORAGE } from '../utils/constants';
import { PaymentService } from '../services/payment.service';
import { NetworkResponse } from '../utils/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent {
  prevRoute: string | undefined = '';
  isLoading: boolean = false;

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.prevRoute = this.router
      .getCurrentNavigation()
      ?.previousNavigation?.finalUrl?.toString();
  }

  openSnackBar(message: string, isOkay: boolean) {
    let snackbarClass = isOkay ? 'snackbar--success' : 'snackbar--error';
    this.snackbar.open(message, '', {
      duration: 3 * 1000,
      panelClass: [snackbarClass],
    });
  }

  async payOne() {
    this.isLoading = true;
    let result: NetworkResponse = await this.paymentService.makePayment(
      '1',
      `${window.location.origin}/content`
    );

    if (result.Error) {
      this.isLoading = false;
      this.openSnackBar(result.Error.message, false);
    }
  }

  async payTwo() {
    this.isLoading = true;
    let result = await this.paymentService.makePayment(
      '2',
      `${window.location.origin}/content`
    );

    if (result.Error) {
      this.isLoading = false;
      this.openSnackBar(result.Error.message, false);
    }
  }

  async payThree() {
    this.isLoading = true;
    let result = await this.paymentService.makePayment(
      '3',
      `${window.location.origin}/content`
    );

    if (result.Error) {
      this.isLoading = false;
      this.openSnackBar(result.Error.message, false);
    }
  }
}
