import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-show-details-metadata',
  templateUrl: './show-details-metadata.component.html',
  styleUrls: ['./show-details-metadata.component.scss']
})
export class ShowDetailsMetadataComponent {
  metaDataForm!: FormGroup;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message : string = ' ';
  submitted = false;

  constructor(private formBuilder:FormBuilder,private http: HttpClient,
    private ContentService: ContentService){}

  ngOnInit(): void{
    this.metaDataForm = this.formBuilder.group({
      showTitle : ['', [Validators.required, Validators.maxLength(50)]],
      director: ['', [Validators.required, Validators.maxLength(50)]],
      year: ['', [Validators.required, Validators.maxLength(50)]]

    })

  }

  async onSubmit(): Promise<void>{
    this.submitted = true;
    if(this.metaDataForm.valid){
      const formData = new FormData
      formData.append('showTitle', this.metaDataForm.get('showTitle')?.value || '')
      formData.append('director', this.metaDataForm.get('director')?.value || '')
      formData.append('year', this.metaDataForm.get('year')?.value || '')

      this.ContentService.postshowDetailMetaData(formData).subscribe(
        response =>{
          if(response.code === 201){
            this.showSaveSuccessAnimation = true;
          }
          else{
            this.showSaveErrorAnimation =true;
            this.message = response.message;
          }
        },
        (error) => {
          this.showSaveErrorAnimation =true;
          this.message = error;
        }
      );
    }
    else{
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }


}
