<div >
  <ngx-slick-carousel class="carousel slick-slider " #slickModal="slick-carousel" [config]="sliderConfig">
    <div ngxSlickItem *ngFor="let Data of contentData" class="slick-slide">
      <mat-card [routerLink]="[routerLink, encodeUrl(Data.slug)]">
        <img mat-card-image src={{Data.image}}  alt="Splash image">
        <h4>{{Data.title}}</h4>
      </mat-card>
    </div>
  </ngx-slick-carousel>
</div>

