export const LOCAL_STORAGE = {
    accessToken: '2lietkapas',
    emailForSignIn: 'emailForSignIn',
    isResetPasswordEmailSent: 'i45wsdww3',
    userMngmtActionCode: 'f323dtwe55'
}

export const PROVIDERS = {
    nifty: "Nifty",
    google: "Google",
    facebook: "Facebook",
    yahoo: "Yahoo"
}

export const USER_MNGMT_ACTIONS = {
    verifyEmail: "verifyEmail",
    resetPassword: "resetPassword"
}
