import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ToggleDescriptionService } from '../../services/toggle-description.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ActivatedRoute } from '@angular/router';
import { MoviesComponent } from 'src/app/movies/movies.component';
import { SeriesComponent } from 'src/app/series/series.component';
import { PodcastsComponent } from 'src/app/podcasts/podcasts.component';
import { IsPaidService } from 'src/app/services/is-paid.service';

@Component({
  selector: 'app-top-content',
  templateUrl: './top-content.component.html',
  styleUrls: ['./top-content.component.scss']
})
export class TopContentComponent {
  @Input() contentData!: { key: string, data: any };
  @Input() isPaid!: boolean;
  routeComponent!: string;
  componentName!:string;
  contentRoutes = [
    'movie',
    'show',
    'podcast',
  ];

  constructor(public toggleDesc: ToggleDescriptionService,
    private router: Router,
    private route:ActivatedRoute
  ){}

  ngOnInit(){
    const routeParts = this.route.snapshot.url.map(segment => segment.path);
    const firstPart = routeParts[0];
    this.routeComponent = firstPart;
  }

  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }

}
