import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import * as jQuery from 'jquery';
import 'slick-carousel';

import { AuthenticationService } from '../services/authentication.service';
import { ContentService } from '../services/content.service';

import {
  movies,
  podcasts,
  shows,
  RssFeed,
  AllData,
  moviesResponse,
  podcastResponse,
  showResponse,
} from 'src/interfaces/content';
import { parseString } from 'xml2js';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { PaymentService } from '../services/payment.service';
import { getSearchParameterByName } from '../utils/httpHandlers';
import { MoviesComponent } from '../movies/movies.component';
import { ProfileService } from '../services/profile.service';
import { IsPaidService } from '../services/is-paid.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  moviesData!: movies[];
  podcasts!: podcasts[];
  shows!: shows[];
  randomPost!: any;
  allData!: any;
  userInfo!: any;
  isPaid!:boolean;

  constructor(
    private ContentService: ContentService,
    private paymentService: PaymentService,
    private isPaidService: IsPaidService
  ) {}

  async ngOnInit() {
    //If payment parameters exist confirm the payment
    if (getSearchParameterByName('tx_ref')) {
      this.paymentService.confirmPayment();
    }
    this.isPaid = await this.paymentService.canActivate();
    this.isPaidService.setPaidStatus(this.isPaid);
    await this.buildAllData();
    this.moviesData = this.allData.movies;
    this.shows = this.allData.shows;
    this.podcasts = this.allData.podcasts;
  }

  async buildAllData() {
    const [moviesData, showsData, podcastsData] = await Promise.all([
      this.ContentService.movieList().toPromise(),
      this.ContentService.showList().toPromise(),
      this.ContentService.podcastList().toPromise(),
    ]);

    this.allData = {
      movies: moviesData?.data,
      shows: showsData?.data,
      podcasts: podcastsData?.data,
    };
    console.log('The data', this.allData);
    const allDataKeys = Object.keys(this.allData);
    const randomArrayKey =
      allDataKeys[Math.floor(Math.random() * allDataKeys.length)];
    const randomArray = this.allData[randomArrayKey];
    const Post = randomArray[Math.floor(Math.random() * randomArray.length)];
    this.randomPost = { key: randomArrayKey, data: Post };
  }

}
