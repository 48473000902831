<div class="image-container" *ngIf="contentData" >
  <img class="img-fluid" [src]="contentData.data.image" fill alt=""/>
  <app-header></app-header>
  <div *ngIf="contentData.key !=='podcasts'" class="overlay py-5">
    <h1>{{contentData.data.title}}</h1>
    <div  class="desc py-2 px-3">{{contentData.data.desc||contentData.data.description}}</div>
    <div *ngIf="(contentData.key === 'movies' || contentData.key==='shows') && (contentData.data.movie_metadata?.director || contentData.data.show_detail_metadata?.director)" class="director-year py-2 px-3">
      <button class="btn btn-sm rounded-pill">
        {{contentData.data.movie_metadata?.director || contentData.data.show_detail_metadata?.director}}
      </button>
    </div>
    <div *ngIf="(contentData.key === 'movies' || contentData.key==='shows') && (contentData.data.movie_metadata?.year || contentData.data.show_detail_metadata?.year)">
      <button class="btn btn-sm rounded-pill">
        {{contentData.data.movie_metadata?.year || contentData.data.show_detail_metadata?.year}}
      </button>
    </div>

  </div>

  <div class="button-overlay px-3 d-flex ">
    <div class="col-4" *ngIf="!(contentData.key === 'podcasts' && routeComponent === 'content')">
      <app-play-button [contentData]="contentData"></app-play-button>
    </div>
    <div class="col-4" *ngIf=" !contentRoutes.includes(routeComponent) && !(contentData.key === 'movies' && routeComponent === 'content' ) ">
      <app-info-button [contentData]="contentData"></app-info-button>
    </div>
    <!--<div class="col-4" *ngIf="!isPaid">-->
    <div class="col-4" *ngIf="!isPaid">
      <app-view-plans [contentData]="contentData"></app-view-plans>
    </div>
  </div>
</div>

<div *ngIf="!contentData">
  <app-header></app-header>
</div>






<!--<div class="image-container" *ngIf="contentData" >
  <img class="img-fluid"  [src]="contentData.data.image"  alt=""/>
  <app-header></app-header>
  <div class="overlay py-5">
    <h1>{{contentData.data.title}}</h1>
    <div class="desc py-2 px-3">{{contentData.data.desc||contentData.data.description}}</div>
  </div>
  <div class="button-overlay px-3 d-flex ">
    <div class="col-4">
      <app-play-button></app-play-button>
      <button (click)="onPlayButton(contentData)" type="button" class="btn btn-sm">
        Play
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-triangle-fill" viewBox="0 0 16 16">
          <path d="M0 14l14-7L0 0v14z" />
        </svg>
      </button>
    </div>
    <div class="col-4">
      <button type="button" class="btn btn-sm" (click)="onInfoButtonClick(contentData)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.75 5.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-1.5 0V5.5zM8 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </svg>
      </button>
    </div>
    <div class="col-4" *ngIf="!isPayed">
      <button type="button" class="btn btn-sm" routerLink="/pay" >
        View Plans
      </button>
    </div>
  </div>
</div>-->

