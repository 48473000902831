
<div class="update-password">
    <form >     
        <mat-form-field appearance="outline">
            <mat-label>Please enter your email address</mat-label>
            <input matInput required name="email" id="email" [formControl] = "emailFormControl">
            <mat-error *ngIf="emailFormControl?.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Enter your new password</mat-label>
            <input matInput required name="password" id="password" [formControl] = "passwordFormControl">
            <mat-error *ngIf="passwordFormControl?.invalid">{{getPasswordErrorMessage()}}</mat-error>
        </mat-form-field>
    </form>
    <mat-error class="network-error">{{networkErrorMessage}}</mat-error>
    <button mat-raised-button color="primary" [disabled]="passwordFormControl.invalid || emailFormControl.invalid" (click)="updatePassword()">Reset</button>
</div>