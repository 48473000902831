import { Component } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  constructor(
    private _profileService: ProfileService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}
  userInfo: any = null;
  subscriptionInfo: any = null;
  plan = '';
  daysToExpiry = '0';
  subscriptionIsActiveStatus: boolean = false;

  openSnackBar(message: string, isOkay: boolean) {
    let snackbarClass = isOkay ? 'snackbar--success' : 'snackbar--error';
    this._snackBar.open(message, '', {
      duration: 3 * 1000,
      panelClass: [snackbarClass],
    });
  }

  exit() {}

  goBack() {
    this.router.navigate(['/content']);
  }

  async ngOnInit() {
    this.userInfo = await this._profileService.getUserInfo();

    this.subscriptionInfo =
      await this._profileService.getUserSubscriptionInfo();
    if (this.subscriptionInfo) {
      this.subscriptionIsActiveStatus = this.subscriptionInfo.sub_active_status;

      const today: moment.Moment = moment();
      const someday: moment.Moment = moment(this.subscriptionInfo.expires);
      const duration: moment.Duration = moment.duration(someday.diff(today));
      const hourDifference: number = someday.diff(today, 'hours');

      if (_.isNaN(duration) || _.isNull(duration)) {
        this.daysToExpiry = '0';
      } else {
        this.daysToExpiry = `${duration.humanize()}`;
      }
      if (this.subscriptionInfo.plan === 1) {
        this.plan = 'Daily';
      } else if (this.subscriptionInfo.plan === 2) {
        this.plan = 'Weekly';
      } else {
        this.plan = 'Monthly';
      }
    }
  }

  async cancelSubscription() {
    let isSubscriptionCancelled =
      await this._profileService.cancelSubscription();
    this.subscriptionIsActiveStatus = isSubscriptionCancelled;

    let snackbarMessage = isSubscriptionCancelled
      ? 'Subscription cancelled successfully !'
      : 'Failed to cancel subscription !';
    this.openSnackBar(snackbarMessage, isSubscriptionCancelled);
    this.ngOnInit();
  }
}
