<div *ngIf="showSaveSuccessAnimation" class="upload-animation" >
  <button>Data Saved Successfully</button>
</div>
<div *ngIf="showSaveErrorAnimation" class="upload-animation" >
  <button>{{message}}</button>
</div>
<div class="form-container p-2">
  <div class="top-title">
    <h4>Upload Podcast Details</h4>
  </div>
  <form class="form-content p-2" [formGroup]="podcastForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
      <div class="p-1 form-group">
        <label>Podcast Title</label>
        <input type="text" class="form-control" formControlName="title" placeholder="Title">
        <div *ngIf="podcastForm.controls['title'].invalid && (podcastForm.controls['title'].dirty || podcastForm.controls['title'].touched || submitted)">
          <div class="formalerts" *ngIf="podcastForm.controls['title'].errors?.['required']">Title is required.</div>
          <div class="formalerts" *ngIf="podcastForm.controls['title'].errors?.['maxLength']">Title exceeds the maximum lenth of 50</div>
        </div>
      </div>
      <div class="p-1 form-group">
        <label ></label>
        <input type="text" class="form-control" formControlName="author" placeholder="Author">
        <div *ngIf="podcastForm.controls['author'].invalid && (podcastForm.controls['author'].dirty || podcastForm.controls['author'].touched || submitted)">
          <div class="formalerts" *ngIf="podcastForm.controls['author'].errors?.['required']">Author is required.</div>
          <div class="formalerts" *ngIf="podcastForm.controls['author'].errors?.['maxLength']">Author exceeds the maximum lenth of 50</div>
        </div>
    </div>



      <button class="my-2" type="submit">Submit</button>
  </form>
</div>
