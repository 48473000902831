import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inactive-btn',
  templateUrl: './inactive-btn.component.html',
  styleUrls: ['./inactive-btn.component.scss']
})
export class InactiveBtnComponent {
  @Input() buttonAction: string = "nwplus"; 
}
