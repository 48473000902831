import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse'; // lib
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';


export const mdbModules = [
  MdbCollapseModule,
  MdbDropdownModule,
  MdbCarouselModule
]
