import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  content:any;
  constructor(private searchService:SearchService){}

  ngOnInit() {
    this.searchService.queryChanges.subscribe((results) => {
      this.content = results;
      console.log(this.content);
    });
  }

}
