<div class="profile-container">
  <app-light-logo />

  <h1>My Profile</h1>

  <div class="content">
    <mat-card class="profile-card">
      <img
        mat-card-image
        src="../../assets/default-profile-pic.png"
        alt="profile picture"
      />
      <mat-card-content>
        <div class="pro-item">
          <span
            >Email: <b>{{ userInfo?.email }}</b>
          </span>
          <p></p>
          <a routerLink="/reset-password">Change password</a>
        </div>
        <div class="pro-item">
          <button
            mat-raised-button
            color="primary"
            class="btn"
            [disabled]="!subscriptionIsActiveStatus"
            (click)="cancelSubscription()"
          >
            Cancel Subscription
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="subscription-card">
      <mat-card-header>
        <mat-card-title>My Subscription</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="sub-item">
          <span> Type</span>
          <mat-chip
            [ngStyle]="{ 'background-color': 'gray', color: 'white' }"
            >{{ plan }}</mat-chip
          >
        </div>
        <mat-divider [ngStyle]="{ color: 'gray' }" />

        <div class="sub-item">
          <p>Plan Status</p>
          <mat-chip
            [ngStyle]="{ 'background-color': 'purple', color: 'white' }"
            >{{
              subscriptionInfo?.active_status ? "Active" : "Inactive"
            }}</mat-chip
          >
        </div>
        <div class="sub-item">
          <p>Subscription Status</p>
          <mat-chip
            ng-model="subscriptionIsActiveStatus"
            [ngStyle]="{ 'background-color': 'purple', color: 'white' }"
            >{{ subscriptionIsActiveStatus ? "Active" : "Cancelled" }}</mat-chip
          >
        </div>
        <mat-divider [ngStyle]="{ color: 'gray' }" />
        <div class="sub-item">
          <p>Time Left</p>
          <p>{{ daysToExpiry }}</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <button mat-raised-button color="primary" class="btn" (click)="goBack()">
    Back
  </button>
</div>
