import { HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { NetworkResponse } from "./interfaces";

 //Error handling
 export const handleUserAuthError = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);      
      
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(error.error.message));
}

 export const handlePaymentError = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);      
      
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(error.error.message));
}
  
export const handleUserInfoError = (error: HttpErrorResponse) => {
  if (error.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.log(error)
    
    if (error.status === 401) {
      //Unauthorized access show pop up or baloon with details of failed credentials
      console.log("errorrrewrew")

    }

    if (error.status === 400) {
      console.log("Ereree")
    }
    
    
  }
  // Return an observable with a user-facing error message.
 // return throwError(() => new Error(error.error.message));
}


//Search parameters
export const getSearchParameterByName = (name: string):string => {
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchParam = urlParams.get(name)
  
  return searchParam as string
}
