import { Injectable } from '@angular/core';
import {
  getSearchParameterByName,
  handlePaymentError,
} from '../utils/httpHandlers';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE } from '../utils/constants';
import { catchError, firstValueFrom, of } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { NetworkResponse } from '../utils/interfaces';
import { AuthenticationService } from './authentication.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthenticationService,
    private profileService:ProfileService,
  ) {}

  isPaid!:boolean;
  payEndpoint = `${environment.apiBaseUrl}/api/v1/pay`;
  confirmPayEndpoint = `${environment.apiBaseUrl}/api/v1/pay/verify`;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE.accessToken)}`,
  });

  //async canActivate(): Promise<boolean | UrlTree> {
  async canActivate(): Promise<boolean>{
    const userPaymentInfoEndpoint = `${environment.apiBaseUrl}/api/v1/payment/info`;
    const token = localStorage.getItem(LOCAL_STORAGE.accessToken);

    let isAuthenticated = await this.auth.canActivate();
    let isPayed = false;

    if (isAuthenticated === true) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        params: new HttpParams().set('latest', 'true'),
      };
      isPayed = await this.freeTrial();
      if(isPayed === false){
        await firstValueFrom(this.http.get(userPaymentInfoEndpoint, httpOptions))
          .then((result: any) => {
            if (result.code === 200) {
              isPayed = result.data.active_status;
            }
          })
          .catch((error) => {
            console.error(error.error);
          });
      }
    }
    //return isPayed ? isPayed : this.router.navigate(['/pay']);
    return isPayed;
  }

  async makePayment(
    planId: string,
    redirectUrl: string
  ): Promise<NetworkResponse> {
    let body = {
      plan_id: planId,
      redirect_url: redirectUrl,
    };

    return await firstValueFrom(
      this.http.post(this.payEndpoint, body, { headers: this.headers })
    )
      .then((result: any) => {
        window.location.href = result.data.link;
        const networkResponse: NetworkResponse = {
          Success: {
            code: 0,
            message: 'success',
          },
        };

        return networkResponse;
      })
      .catch((error) => {
        console.error(error.error);
        const errorCode = error.code;
        const errorMessage = error.error.message;

        const networkResponse: NetworkResponse = {
          Error: {
            code: errorCode,
            message: errorMessage,
          },
        };
        return networkResponse;
      });
  }

  async confirmPayment(): Promise<NetworkResponse> {
    let networkResponse: NetworkResponse = {
      Error: {
        code: 99,
        message: 'Payment failed',
      },
    };
    //check for transaction reference

    const transactionReference = getSearchParameterByName('tx_ref');
    const parameters = new HttpParams().set('tx_ref', transactionReference);

    await firstValueFrom(
      this.http.get(this.confirmPayEndpoint, {
        headers: this.headers,
        params: parameters,
      })
    )
      .then((response: any) => {
        switch (response.code) {
          case 200:
            networkResponse = {
              Success: {
                code: response.code,
                message: 'Payment successful',
              },
            };
            break;
          default:
            networkResponse = {
              Error: {
                code: response.code,
                message: 'Payment Failed',
              },
            };
    }
      })
      .catch((error) => {
        if (error.error.code == 404) {
          networkResponse = {
            Error: {
              code: error.error.code,
              message: 'Payment could not be verified',
            },
          };
    }
        console.error(error.error);
      });
    return networkResponse;
  }



  async freeTrial(){
    let isPayed: boolean = false;
    let userInfo:any;
    userInfo = await this.profileService.getUserInfo();
    const dateJoined = new Date(userInfo.date_joined.replace(/\.\d+Z$/, 'Z'));
    const currentTime = new Date();
    const timeDifferenceMs = currentTime.getTime() - dateJoined.getTime();
    const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
    if(timeDifferenceHours < 24){
      isPayed = true;
    }
    return isPayed
  }




}
