import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { NetworkResponse } from 'src/app/utils/interfaces';
import { User } from 'src/interfaces/user';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  users: User[] = [];
  activeSubscribers: User[] = [];
  inactiveSubscribers: User[] = [];
  totalSales: number = 0;
  loading: boolean = true;

  constructor(private readonly admin: AdminService) {}

  async ngOnInit() {
    try {
      const usersResponse: NetworkResponse = await this.admin.getAllUsers();

      if (usersResponse.Success) {
        this.users = usersResponse.Success.data as User[];
        this.categorizeSubscribers();
      } else {
        console.error(usersResponse.Error ? usersResponse.Error.message : "No error occurred");
      }

      const paymentResponse: NetworkResponse = await this.admin.getAllUserPaymentInfoStatus();

      if (paymentResponse.Success) {
        this.calculateTotalSales(paymentResponse.Success.data);
      } else {
        console.error(paymentResponse.Error ? paymentResponse.Error.message : "No error occurred");
      }
    } catch (error) {
      console.error("An error occurred while fetching data", error);
    } finally {
      this.loading = false;
    }
  }

  categorizeSubscribers() {
    const nonAdminUsers = this.users.filter(user => !user.isAdmin);
    this.activeSubscribers = nonAdminUsers.filter(user => user.isEmailVerified);
    this.inactiveSubscribers = nonAdminUsers.filter(user => !user.isEmailVerified);
  }

  calculateTotalSales(payments: any[]) {
    this.totalSales = payments.reduce((sum, payment) => sum + (payment.amount || 0) + (payment.pending || 0), 0);
  }
}