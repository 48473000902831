import { createReducer, on } from '@ngrx/store';
import {
  resetState,
  loadContent,
  loadContentSuccess,
  loadContentFailure,
  selectContent,
  selectContentSuccess,
  selectContentError,
  setSelectedContentId,
  setSelectedContentType,
  setSelectedContent
} from '../actions/content.actions';
import { initialContentState } from '../state/content.state';

export const contentReducer = createReducer(
  initialContentState,
  on(loadContent, state => {
    return { ...state, loading: true, error: null };
  }),
  on(loadContentSuccess, (state, { movies, shows, podcasts }) => {
    return {
      ...state,
      movies,
      shows,
      podcasts,
      loading: false,
      error: null,
      loaded: true
    };
  }),
  on(loadContentFailure, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error
    };
  }),
  on(selectContent, (state, { contentId, contentType }) => {
    return {
      ...state,
      selectedContentId: contentId,
      selectedContentType: contentType,
      loading: true,
      error: null
    };
  }),
  on(selectContentSuccess, (state, { content }) => {
    return {
      ...state,
      selectedContent: content,
      loading: false,
      error: null
    };
  }),
  on(selectContentError, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error
    };
  }),
  on(setSelectedContentId, (state, { contentId }) => {
    return {
      ...state,
      selectedContentId: contentId
    };
  }),
  on(setSelectedContentType, (state, { contentType }) => {
    return {
      ...state,
      selectedContentType: contentType
    };
  }),
  on(setSelectedContent, (state, { content }) => {
    return {
      ...state,
      selectedContent: content
    };
  }),
  on(resetState, () => {
    return initialContentState;
  })
);
