import { DOCUMENT } from '@angular/common';
import { OnInit, Component, Inject } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  constructor(@Inject(DOCUMENT) private document:Document){}
  ngOnInit(){
    this.document.body.classList.add('home_background');
  }

  ngOnDestroy() {
    this.document.body.classList.remove('home_background');
  }

}
