import { Component, HostListener, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { ThemesService } from '../../services/themes.service';
import { LOCAL_STORAGE } from 'src/app/utils/constants';
import { HttpClient } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  selectedTheme!: string;
  content!: any[];
  excludeRoute!: boolean;
  constructor(
    public router: Router,
    private contentService: ContentService,
    private themeService: ThemesService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private searchService: SearchService
  ) {}
  query: string = '';
  searchResults: any = {};

  ngOnInit() {
    const excludeRoutes = ['', 'signin', 'signup', 'splash'];

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.excludeRoute = excludeRoutes.includes(
          event.urlAfterRedirects.split('/')[1]
        );
      }
    });
  }

  onThemeSelected(selectedTheme: string) {
    console.log('header link clicked');
    //const selectedTheme = (event.target as HTMLElement).parentNode?.textContent?.trim();
    console.log(selectedTheme);
    this.themeService.setSelectedTheme(selectedTheme || '');
  }
  onSearch() {
    if (this.router.url !== '/filter') {
      this.router.navigate(['/filter']); /*.then(()=>{
        const searchInput = this.elementRef.nativeElement.querySelector('#searchInput');
        searchInput.focus();
        this.renderer.selectRootElement(searchInput).click();
      });*/
    }
  }

  search() {
    if (this.query.length > 0) {
      this.contentService.filter(this.query).subscribe((res) => {
        this.searchService.setSearchResults(res);
        //console.log(this.searchService.getSearchResults());
      });
    } else {
      this.searchService.setSearchResults('');
    }
  }

  onsearchQuery(event: any) {
    if (this.query.length > 0) {
      this.contentService.filter(this.query).subscribe((res) => {
        this.searchService.setSearchResults(res);
        console.log(this.searchResults);
      });
    } else {
      this.searchService.setSearchResults({});
    }
  }

  onProfileClick() {
    this.router.navigate(['/profile']);
  }
  signOut() {
    localStorage.removeItem(LOCAL_STORAGE.accessToken);
    this.router.navigate(['/signin']);
  }
}
