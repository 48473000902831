<header *ngIf="!excludeRoute">
  <nav class="navbar navbar-expand navbar-dark">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/content"
        ><img src="../assets/logo2.png"
      /></a>
      <div class="d-flex align-items-center">
        <!-- links -->
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/content"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              >Home</a
            >
          </li>

          <!-- Dropdown Categories Menu -->
          <li mdbDropdown class="nav-item dropdown categories-menu">
            <a
              class="nav-link"
              role="button"
              id="navbarDropdownMenuLink"
              role="button"
              mdbDropdownToggle
              aria-expanded="false"
              [text]="selectedTheme || 'Categories'"
              routerLinkActive="active"
            ></a>
            <ul
              mdbDropdownMenu
              class="dropdown-menu justify-content-center"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li>
                <a
                  (click)="onThemeSelected('Family')"
                  class="dropdown-item"
                  [routerLink]="['/themes', 'Family']"
                  routerLinkActive="active"
                  >Family</a
                >
              </li>
              <li>
                <a
                  (click)="onThemeSelected('Kids')"
                  class="dropdown-item"
                  [routerLink]="['/themes', 'Kids']"
                  routerLinkActive="active"
                  >Kids</a
                >
              </li>
              <li>
                <a
                  (click)="onThemeSelected('Animation')"
                  class="dropdown-item"
                  [routerLink]="['/themes', 'Animation']"
                  routerLinkActive="active"
                  >Animation</a
                >
              </li>
              <li>
                <a
                  (click)="onThemeSelected('Lifestyle')"
                  class="dropdown-item"
                  [routerLink]="['/themes', 'Lifestyle']"
                  routerLinkActive="active"
                  >Lifestyle</a
                >
              </li>
            </ul>
          </li>

          <!-- Horizontal Menu -->
          <li class="nav-item horizontal-menu">
            <a
              (click)="onThemeSelected('Family')"
              class="nav-link"
              [routerLink]="['/themes', 'Family']"
              routerLinkActive="active"
              >Family</a
            >
          </li>
          <li class="nav-item horizontal-menu">
            <a
              (click)="onThemeSelected('Kids')"
              class="nav-link"
              [routerLink]="['/themes', 'Kids']"
              routerLinkActive="active"
              >Kids</a
            >
          </li>
          <li class="nav-item horizontal-menu">
            <a
              (click)="onThemeSelected('Animation')"
              class="nav-link"
              [routerLink]="['/themes', 'Animation']"
              outerLinkActive="active"
              >Animation</a
            >
          </li>
          <li class="nav-item horizontal-menu">
            <a
              (click)="onThemeSelected('Lifestyle')"
              class="nav-link"
              [routerLink]="['/themes', 'Lifestyle']"
              routerLinkActive="active"
              >Lifestyle</a
            >
          </li>

          <li class="nav-item">
            <div class="input-group search-box">
              <input
                #searchInput
                type="search"
                class="form-control"
                placeholder="Search"
                (click)="onSearch()"
                [(ngModel)]="query"
                (input)="search()"
                aria-label="Search"
                aria-describedby="search-addon"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0" id="search-addon">
                  <i class="fas fa-search" (click)="onsearchQuery($event)"></i>
                </span>
              </div>
            </div>
          </li>
          <li class="nav-item horizontal-menu">
            <a (click)="onProfileClick()" class="nav-link profile">Profile</a>
          </li>

          <li class="nav-item">
            <a class="nav-link logState" (click)="signOut()">Sign Out</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
