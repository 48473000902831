import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './signup/signup.component';

import { TruncatePipe } from './pipes/truncate.pipe';
import { SearchService } from './services/search.service';

import { MatModules } from './matModules';
import { mdbModules } from './mdbModules';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SigninComponent } from './signin/signin.component';
import { ContentComponent } from './content/content.component';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { GoogleButtonComponent } from './components/google-button/google-button.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { FooterComponent } from './components/footer/footer.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { PlayerComponent } from './player/player.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeComponent } from './home/home.component';
import { SplashComponent } from './splash/splash.component';
import { ActiveBtnComponent } from './components/active-btn/active-btn.component';
import { InactiveBtnComponent } from './components/inactive-btn/inactive-btn.component';
import { SplashCardComponent } from './components/splash-card/splash-card.component';
import { ColoredLogoComponent } from './components/colored-logo/colored-logo.component';

import { PodcastsComponent } from './podcasts/podcasts.component';
import { LightLogoComponent } from './components/light-logo/light-logo.component';
import { PayComponent } from './pay/pay.component';
import { HeaderComponent } from './components/header/header.component';
import { SeriesComponent } from './series/series.component';
import { MoviesComponent } from './movies/movies.component';
import { TopContentComponent } from './components/top-content/top-content.component';
import { FeedsComponent } from './feeds/feeds.component';
import { SliderComponent } from './components/slider/slider.component';
import { ThemesComponent } from './themes/themes.component';
import { ContentDescriptionComponent } from './components/content-description/content-description.component';
import { OptionButtonsComponent } from './components/option-buttons/option-buttons.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsNConditionsComponent } from './terms-n-conditions/terms-n-conditions.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { IonicModule } from '@ionic/angular';
import { DashboardComponent } from './admin/views/dashboard/dashboard.component';
import { UploadsComponent } from './admin/views/uploads/uploads.component';
import { AdminComponent } from './admin/admin.component';
import { MoviesUploadsComponent } from './components/movies-uploads/movies-uploads.component';
import { PodcastDetailsUploadsComponent } from './components/podcast-details-uploads/podcast-details-uploads.component';
import { PodcastEpisodesUploadsComponent } from './components/podcast-episodes-uploads/podcast-episodes-uploads.component';
import { ShowDetailsUploadsComponent } from './components/show-details-uploads/show-details-uploads.component';
import { SeasonDetailsUploadsComponent } from './components/season-details-uploads/season-details-uploads.component';
import { ShowEpisodesUploadsComponent } from './components/show-episodes-uploads/show-episodes-uploads.component';
import { PodcastUploadsComponent } from './components/podcast-uploads/podcast-uploads.component';
import { ShowUploadsComponent } from './components/show-uploads/show-uploads.component';
import { MovieMetadataComponent } from './components/movie-metadata/movie-metadata.component';
import { PodcastDetailsMetadataComponent } from './components/podcast-details-metadata/podcast-details-metadata.component';
import { ShowDetailsMetadataComponent } from './components/show-details-metadata/show-details-metadata.component';
import { FeedDetailsUploadComponent } from './components/feed-details-upload/feed-details-upload.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { PlayButtonComponent } from './components/play-button/play-button.component';
import { MoreInfoButtonComponent } from './components/more-info-button/more-info-button.component';
import { ViewPlansButtonComponent } from './components/view-plans-button/view-plans-button.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { ViewPlansComponent } from './components/view-plans/view-plans.component';
import { FilterComponent } from './filter/filter.component';
import { ProfileComponent } from './profile/profile.component';
import { OverlayComponent } from './components/overlay/overlay.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    ContentComponent,
    GoogleButtonComponent,
    EmailConfirmationComponent,
    FooterComponent,
    VideoPlayerComponent,
    PlayerComponent,
    HomeComponent,
    SplashComponent,
    ActiveBtnComponent,
    InactiveBtnComponent,
    SplashCardComponent,
    ColoredLogoComponent,
    TruncatePipe,
    PodcastsComponent,
    LightLogoComponent,
    PayComponent,
    HeaderComponent,
    SeriesComponent,
    MoviesComponent,
    TopContentComponent,
    FeedsComponent,
    SliderComponent,
    ThemesComponent,
    ContentDescriptionComponent,
    OptionButtonsComponent,
    ResetPasswordComponent,
    PrivacyComponent,
    TermsNConditionsComponent,
    PagenotfoundComponent,
    UpdatePasswordComponent,
    DashboardComponent,
    UploadsComponent,
    AdminComponent,
    MoviesUploadsComponent,
    PodcastDetailsUploadsComponent,
    PodcastEpisodesUploadsComponent,
    ShowDetailsUploadsComponent,
    SeasonDetailsUploadsComponent,
    ShowEpisodesUploadsComponent,
    PodcastUploadsComponent,
    ShowUploadsComponent,
    MovieMetadataComponent,
    PodcastDetailsMetadataComponent,
    ShowDetailsMetadataComponent,
    FeedDetailsUploadComponent,
    AudioPlayerComponent,
    PlayButtonComponent,
    MoreInfoButtonComponent,
    ViewPlansButtonComponent,
    InfoButtonComponent,
    ViewPlansComponent,
    FilterComponent,
    ProfileComponent,
    OverlayComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    BrowserAnimationsModule,
    MatModules,
    mdbModules,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    IonicModule.forRoot(),
  ],
  providers: [SearchService],
  bootstrap: [AppComponent],
})
export class AppModule {}
