<div class="container-fluid">
  <app-top-content [contentData]="showData" [isPaid]="isPaid"></app-top-content>
  <div class="btn-group py-2">
    <select class="btn btn-sm py-3" #seasonNo
      (change)="filterEpisodes(seasonNo.value)"
      style="  background-color:#473062; color: #FFFFFF;">
      <option *ngFor="let season of show.seasons" [value]="season.no">
        Season {{season.no}}
      </option>
    </select>
  </div>

  <div *ngFor="let season of show.seasons">
    <div *ngIf="season.no === seasonNumber">
      <div class="row episode m-4 p-1" *ngFor="let episode of season.seasonEpisodes">
        <div class="col-6">
          <ng-container *ngIf="isPaid">
            <app-video-player [media]="{
              src: episode.converted_path ? episode.converted_path : episode.episode,
              type: episode.converted_path ? (episode.mediaType || 'application/x-mpegURL') : 'video/mp4',
              thumbnail: episode.thumbnail || '../../assets/logo.png',
              ratio: '1.85:1',
              autoplay: false}"
              class="small-video">
            </app-video-player>
          </ng-container>
          <ng-container *ngIf="isPaid === false">
            <div class="video-container">
              <div class="lock-overlay">
                <i class="fas fa-lock fa-3x " style="color: #473062;"></i>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-6 episodeDesc">
          {{episode.episodeDesc}}
        </div>
      </div>
    </div>



  </div>
</div>
