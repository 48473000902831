<div class="container-fluid ">

  <app-top-content [contentData]="randomPost"></app-top-content>
  <div class="content">

    <div *ngIf="content.movies.length > 0">
      <h3 class="py-3">Movies</h3>
      <app-slider [contentData]="content.movies" routerLink="/movie" ></app-slider>
    </div>

    <div *ngIf="content.podcasts.length > 0">
      <h3 class="py-3">Podcasts</h3>
      <app-slider [contentData]="content.podcasts" routerLink="/podcast" ></app-slider>
    </div>

    <div *ngIf="content.shows.length > 0">
      <h3 class="py-3">Shows</h3>
      <app-slider [contentData]="content.shows" routerLink="/show" ></app-slider>
    </div>
  </div>
