<div class="container">
  <div class="row justify-content-center">
    <div class="uploads-container">
      <div class="row cards">
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Show Details</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showShowDetails()"  routerLink="uploadShowDetails"></i>
          </div>
        </div>
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Season Details</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showSeasonDetails()"  routerLink="uploadSeasonDetails"></i>
          </div>
        </div>
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Show Episodes</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showShowEpisodes()"  routerLink="uploadShowEpisodes"></i>
          </div>
        </div>
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Show MetaData</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showShowMetaData()"  routerLink="uploadShowMetaData"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <app-show-details-uploads *ngIf="showDetailsState"></app-show-details-uploads>
  <app-season-details-uploads *ngIf="seasonDetailsState"></app-season-details-uploads>
  <app-show-episodes-uploads *ngIf="showEpisodesState"></app-show-episodes-uploads>
  <app-show-details-metadata *ngIf="showMetaDataState"></app-show-details-metadata>
</div>
