import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { LOCAL_STORAGE } from '../utils/constants';


@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent {
  prevRoute: string | undefined =""
  constructor(private auth: AuthenticationService, private router: Router) {
    this.prevRoute = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString()
  }
  
  ngOnInit() {
    if (!this.prevRoute) {
      localStorage.removeItem(LOCAL_STORAGE.emailForSignIn)
      this.router.navigate(['/signin'])
    }
  }
  
  email = window.localStorage.getItem(LOCAL_STORAGE.emailForSignIn);

  resendLink() {
    this.auth.sendEmailVerification();

  }
  onOkayButtonClick() {
    localStorage.removeItem(LOCAL_STORAGE.emailForSignIn)
    this.router.navigate(['/signin'])
  }
}
