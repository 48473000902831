<header *ngIf="!excludeRoute">
  <nav class="navbar navbar-expand navbar-dark">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/content">
        <img src="../assets/logo2.png" alt="Nifty Work Plus Tv" />
      </a>
      <div class="d-flex align-items-center">
        <!-- links -->
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" routerLink="/content" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
              Home
            </a>
          </li>

          <!-- Dropdown Categories Menu -->
          <li mdbDropdown class="nav-item dropdown categories-menu">
            <a class="nav-link" role="button" id="navbarDropdownMenuLink" tabindex="0" mdbDropdownToggle aria-expanded="false" [text]="selectedTheme || 'Categories'" routerLinkActive="active">
              {{ selectedTheme || 'Categories' }}
            </a>
            <ul mdbDropdownMenu class="dropdown-menu justify-content-center" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a (click)="onThemeSelected('Family')" class="dropdown-item" [ngClass]="{'disabled-link': !hasActiveSubscription}">
                  Family
                </a>
              </li>
              <li>
                <a (click)="onThemeSelected('Kids')" class="dropdown-item" [ngClass]="{'disabled-link': !hasActiveSubscription}">
                  Kids
                </a>
              </li>
              <li>
                <a (click)="onThemeSelected('Animation')" class="dropdown-item" [ngClass]="{'disabled-link': !hasActiveSubscription}">
                  Animation
                </a>
              </li>
              <li>
                <a (click)="onThemeSelected('Lifestyle')" class="dropdown-item" [ngClass]="{'disabled-link': !hasActiveSubscription}">
                  Lifestyle
                </a>
              </li>
            </ul>
          </li>

          <!-- Horizontal Menu -->
          <li class="nav-item horizontal-menu">
            <a (click)="onThemeSelected('Family')" class="nav-link" [ngClass]="{'disabled-link': !hasActiveSubscription}">
              Family
            </a>
          </li>
          <li class="nav-item horizontal-menu">
            <a (click)="onThemeSelected('Kids')" class="nav-link" [ngClass]="{'disabled-link': !hasActiveSubscription}">
              Kids
            </a>
          </li>
          <li class="nav-item horizontal-menu">
            <a (click)="onThemeSelected('Animation')" class="nav-link" [ngClass]="{'disabled-link': !hasActiveSubscription}">
              Animation
            </a>
          </li>
          <li class="nav-item horizontal-menu">
            <a (click)="onThemeSelected('Lifestyle')" class="nav-link" [ngClass]="{'disabled-link': !hasActiveSubscription}">
              Lifestyle
            </a>
          </li>

          <!-- Search Filters -->
          <li class="nav-search">
            <div class="input-group search-box">
              <div class="input-group-prepend dropdown">
                <button class="btn btn-outline-secondary dropdown-toggle content-type-button" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ capitalizeFirstLetter(contentType) }}
                </button>
                <div class="dropdown-menu content-type-dropdown-menu">
                  <a class="dropdown-item" (click)="setContentType('Movies')">Movies</a>
                  <a class="dropdown-item" (click)="setContentType('Shows')">Shows</a>
                  <a class="dropdown-item" (click)="setContentType('Podcasts')">Podcasts</a>
                  <a class="dropdown-item" (click)="setContentType('RssFeeds')">RSS Feeds</a>
                  <a class="dropdown-item" (click)="setContentType('All')">All</a>
                </div>
              </div>
              <div class="input-group-prepend dropdown">
                <button class="btn btn-outline-secondary dropdown-toggle filter-button" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ selectedFilter }}
                </button>
                <div class="dropdown-menu filter-dropdown-menu">
                  <a class="dropdown-item" (click)="setFilter('Title')">Title</a>
                  <a class="dropdown-item" (click)="setFilter('Content Theme')">Content Theme</a>
                  <a class="dropdown-item" (click)="setFilter('Genre')">Genre</a>
                  <a class="dropdown-item" (click)="setFilter('Director')">Director</a>
                  <a class="dropdown-item" (click)="setFilter('Producer')">Producer</a>
                  <a class="dropdown-item" (click)="setFilter('Release Date')">Release Date</a>
                </div>
              </div>
              <input #searchInput type="search" class="form-control search-input" placeholder="Search" [(ngModel)]="filterValue" (input)="search()" aria-label="Search" aria-describedby="search-addon" />
              <span class="input-group-text border-0 clear-icon" id="clear-addon">
                <i class="fas fa-times" (click)="clearSearch()"></i>
              </span>
              <div class="input-group-append">
                <span class="input-group-text border-0" id="search-addon">
                  <i class="fas fa-search" (click)="onsearchQuery($event)"></i>
                </span>
              </div>
            </div>
          </li>

          <li class="nav-item horizontal-menu">
            <a (click)="onProfileClick()" class="nav-link profile">Profile</a>
          </li>

          <li class="nav-item">
            <a class="nav-link logState" (click)="signOut()">Sign Out</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>