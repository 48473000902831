import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteWrite } from '@ionic/core';

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss']
})
export class InfoButtonComponent {
  @Input() contentData!: {key:string, data:any};

  constructor(private router:Router){}

  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }

  onInfoButtonClick(contentData: any) {
    console.log(contentData);

    if (contentData.key === 'movies') {
      this.router.navigate(['/movie', this.encodeUrl(contentData.data.slug)]);
    } else if (contentData.key === 'shows') {
      this.router.navigate(['/show', this.encodeUrl(contentData.data.slug)]);
    } else if (contentData.key === 'podcasts') {
      this.router.navigate(['/podcast', this.encodeUrl(contentData.data.slug)]);
    }
  }

}
