import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleDescriptionService {

  ///constructor() {}
  truncatedDesc!: string;
  readMoreText: string = "Read more";
  showFullDescription: boolean = false;

  toggleDescription(desc: string) {
    this.showFullDescription = !this.showFullDescription;
    if (this.showFullDescription) {
      this.truncatedDesc = desc;
      this.readMoreText = "Read less";
    } else {
      this.truncatedDesc = desc.substr(0, 180);
      this.readMoreText = "Read more";
    }
  }
}
