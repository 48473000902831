<div *ngIf="showSaveSuccessAnimation" class="upload-animation" >
  <button>Movie Meta Data Saved Successfully</button>
</div>
<div *ngIf="showSaveErrorAnimation" class="upload-animation" >
  <button>{{message}}</button>
</div>
<div class="form-container p-2" >
  <div class="top-title">
    <h4>Upload Show MetaData</h4>
  </div>
  <form class="form-content p-2" [formGroup]="metaDataForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
      <div class="p-1 form-group">
        <label>Show Title</label>
        <input type="text" class="form-control" formControlName="showTitle" placeholder="Show Title">
        <div *ngIf="metaDataForm.controls['showTitle'].invalid && (metaDataForm.controls['showTitle'].dirty || metaDataForm.controls['showTitle'].touched || submitted)">
          <div class="formalerts" *ngIf="metaDataForm.controls['showTitle'].errors?.['required']">Title is required.</div>
          <div class="formalerts" *ngIf="metaDataForm.controls['showTitle'].errors?.['maxLength']">Title exceeds the maximum lenth of 50</div>
        </div>
      </div>

      <div class="p-1 form-group">
        <label>Show Director</label>
        <input class="form-control" formControlName="director" placeholder="Director's Name">
        <div *ngIf="metaDataForm.controls['director'].invalid && (metaDataForm.controls['director'].dirty || metaDataForm.controls['director'].touched || submitted)">
          <div class="formalerts" *ngIf="metaDataForm.controls['director'].errors?.['required']">Director is required.</div>
          <div class="formalerts" *ngIf="metaDataForm.controls['director'].errors?.['maxLength']">Director exceeds the maximum lenth of 50</div>
        </div>
      </div>

      <div class="p-1 form-group">
          <input  type="number" class="form-control" formControlName="year" placeholder="Year of Release">
      </div>

      <button class="my-2" type="submit">Submit</button>
  </form>
</div>
