import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, filter, take } from 'rxjs/operators';
import { ContentState } from '../store/state/content.state';
import {
  selectContent,
  selectContentLoading,
  selectContentError
} from '../store/selectors/content.selectors';
import { Media } from 'src/interfaces/player';
import { ContentItem } from 'src/interfaces/content';
import { selectContent as selectContentAction } from '../store/actions/content.actions';
import moment from 'moment';

@Component({
  selector: 'app-podcast-detail',
  templateUrl: './podcast-detail.component.html',
  styleUrls: ['./podcast-detail.component.scss']
})
export class PodcastDetailComponent implements OnInit {
  podcast$ = new BehaviorSubject<Media | null>(null);
  episodes$!: Observable<any[]>;
  loading$!: Observable<boolean>;
  error$!: Observable<string | null>;
  modalData: Media | null = null;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<ContentState>,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(
      switchMap(params => {
        const contentId = params['id']; // This is actually the contentId
        const contentType = this.route.snapshot.url[0].path; // Get content type from URL
        return of({ contentId, contentType });
      }),
      take(1)
    ).subscribe(({ contentId, contentType }) => {
      if (contentId && contentType) {
        this.store.dispatch(selectContentAction({ contentId, contentType }));
        this.store.select(selectContent).pipe(
          filter((selectedContent: ContentItem | null) => !!selectedContent),
          map((selectedContent: ContentItem | null) => {
            if (selectedContent) {
              const episodes = selectedContent.episodes || [];
              this.episodes$ = of(episodes);
              return this.mapToMedia(selectedContent);
            }
            return null;
          })
        ).subscribe(media => {
          this.podcast$.next(media);
        });
      }
    });

    this.loading$ = this.store.select(selectContentLoading);
    this.error$ = this.store.select(selectContentError);
  }

  // Map content item to media object
  private mapToMedia(data: ContentItem): Media {
    return {
      id: data.id,
      title: data.title,
      description: data.description,
      contentId: data.contentId,
      contentTheme: data.contentTheme,
      src: data.podcastUrl ?? '',
      type: 'audio/mpeg',
      thumbnail: data.image,
      label: data.label,
      resolution: data.resolution,
      ratio: data.ratio,
      autoplay: data.autoplay,
      image: data.image,
      episodes: data.episodes,
      podcastMetadata: data.podcastMetadata ? {
        producer: data.podcastMetadata.producer,
        director: data.podcastMetadata.director,
        releaseDate: this.formatReleaseDate(data.podcastMetadata.releaseDate),
        duration: data.podcastMetadata.duration,
        rating: data.podcastMetadata.rating,
        genre: data.podcastMetadata.genre
      } : undefined
    };
  }

  // Map episode to media object
  private mapEpisodeToMedia(episode: any): Media {
    return {
      id: episode.id,
      title: episode.title,
      description: episode.description,
      contentId: episode.contentId,
      contentTheme: episode.contentTheme,
      src: episode.podcastEpisodeUrl ?? '',
      type: 'audio/mpeg',
      thumbnail: episode.image,
      label: episode.label,
      resolution: episode.resolution,
      ratio: episode.ratio,
      autoplay: true, // autoplay when episode is selected
      image: episode.image,
      rating: episode.rating ?? 0,
      podcastEpisodeMetadata: episode.podcastEpisodeMetadata ? {
        author: episode.podcastEpisodeMetadata.author,
        releaseDate: this.formatReleaseDate(episode.podcastEpisodeMetadata.releaseDate),
        duration: episode.podcastEpisodeMetadata.duration,
        genre: episode.podcastEpisodeMetadata.genre || 'General',
      } : undefined
    };
  }

  private formatReleaseDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  goBack() {
    this.router.navigate(['/content']);
  }

  generateStars(rating: number): boolean[] {
    return Array(10).fill(false).map((_, i) => i < rating);
  }

  playEpisode(episode: any): void {
    const newMedia = this.mapEpisodeToMedia(episode);
    this.podcast$.pipe(take(1)).subscribe(currentMedia => {
      if (currentMedia?.src !== newMedia.src) {
        this.podcast$.next(newMedia);
      }
    });
  }

  openPodcastInfo(podcast: Media): void {
    this.modalData = podcast;
    this.cdr.detectChanges(); // Trigger change detection
    this.ensureModalVisible(); // Additional check to force visibility
  }

  closeModal(): void {
    this.modalData = null;
    this.cdr.detectChanges(); // Trigger change detection
  }

  ensureModalVisible(): void {
    const modalElement = document.querySelector('.modal') as HTMLElement;
    if (modalElement) {
      modalElement.style.display = 'flex';
      modalElement.style.visibility = 'visible';
      modalElement.style.opacity = '1';
      modalElement.style.zIndex = '1000';
      modalElement.style.justifyContent = 'center';
      modalElement.style.alignItems = 'center';
      modalElement.style.position = 'fixed';
      modalElement.style.top = '0';
      modalElement.style.left = '0';
      modalElement.style.width = '100%';
      modalElement.style.height = '100%';
      modalElement.style.background = 'rgba(0, 0, 0, 0.8)';
      modalElement.style.transition = 'opacity 0.3s ease, visibility 0.3s ease';

      const modalContent = modalElement.querySelector('.modal-content') as HTMLElement;
      if (modalContent) {
        modalContent.style.position = 'relative';
        modalContent.style.background = 'linear-gradient(135deg, #fff, #f0f0f0)';
        modalContent.style.padding = '20px';
        modalContent.style.borderRadius = '10px';
        modalContent.style.maxWidth = '600px';
        modalContent.style.width = '90%';
        modalContent.style.textAlign = 'left';
        modalContent.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.2)';
        modalContent.style.animation = 'fade-in 0.4s ease-out';

        const keyframes = `
          @keyframes fade-in {
            from {
              opacity: 0;
              transform: translateY(-20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `;
        const styleSheet = document.createElement("style");
        styleSheet.media = "all";
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);

        const responsiveStyles = `
          @media (max-width: 600px) {
            .modal-content {
              padding: 15px;
              font-size: 0.9rem;
            }
            .modal-content h2 {
              font-size: 1.5rem;
            }
            .modal-content p {
              font-size: 0.9rem;
            }
          }
        `;
        styleSheet.innerText += responsiveStyles;

        const closeButton = modalContent.querySelector('.close') as HTMLElement;
        if (closeButton) {
          closeButton.style.position = 'absolute';
          closeButton.style.top = '10px';
          closeButton.style.right = '10px';
          closeButton.style.fontSize = '1.5rem';
          closeButton.style.color = '#333';
          closeButton.style.cursor = 'pointer';
          closeButton.style.transition = 'color 0.2s ease';

          closeButton.addEventListener('mouseover', () => {
            closeButton.style.color = '#d9534f';
          });

          closeButton.addEventListener('mouseout', () => {
            closeButton.style.color = '#333';
          });
        }

        const title = modalContent.querySelector('h2') as HTMLElement;
        if (title) {
          title.style.fontSize = '2rem';
          title.style.marginBottom = '10px';
          title.style.color = '#333';
        }

        const paragraphs = modalContent.querySelectorAll('p') as NodeListOf<HTMLElement>;
        paragraphs.forEach(p => {
          p.style.fontSize = '1rem';
          p.style.marginBottom = '10px';
          p.style.color = '#666';
          p.style.lineHeight = '1.6';
        });

        const strongElements = modalContent.querySelectorAll('strong') as NodeListOf<HTMLElement>;
        strongElements.forEach(strong => {
          strong.style.color = '#555';
        });
      }
    }
  }
}