import { Component } from '@angular/core';

@Component({
  selector: 'app-more-info-button',
  templateUrl: './more-info-button.component.html',
  styleUrls: ['./more-info-button.component.scss']
})
export class MoreInfoButtonComponent {

}
