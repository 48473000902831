import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.scss']
})
export class ViewPlansComponent {
  @Input() contentData!:{key:string, data:any}

}
