import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { jwtDecode } from "jwt-decode";
import { environment } from "src/environments/environment";
import { LOCAL_STORAGE } from "../utils/constants";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  constructor(private http: HttpClient, private router: Router) {}

  // Handle user management action
  handleUserManagementAction(mode: string, actionCode: string) {
    switch (mode) {
      case 'resetPassword':
        this.router.navigate(['/update-password']);
        break;
      case 'verifyEmail':
        this.verifyEmail(actionCode).subscribe({
          next: () => {
            this.router.navigate(['/verify-email']);
          },
          error: (err) => {
            console.error('Email verification failed', err);
          }
        });
        break;
      default:
        break;
    }
  }
  
  // Sign up
  signUp(email: string, username: string, phoneNumber: string, password: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/signup`;
    const body = { email, username, phoneNumber, password };
    return this.http.post<any>(url, body);
  }

  // Sign in
  signIn(email: string, password: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/signin`;
    const body = { email, password };
    return this.http.post<any>(url, body).pipe(
      tap((response: any) => {
        if (response.success && response.data.accessToken) {
          localStorage.setItem(LOCAL_STORAGE.accessToken, response.data.accessToken);
        }
      })
    );
  }

  // Verify email
  verifyEmail(token: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/verify-email/${token}`;
    return this.http.get<any>(url);
  }

  // Send email verification
  sendEmailVerification(): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/send-email-verification`;
    return this.http.get<any>(url);
  }

  // Send password reset email
  sendPasswordResetEmail(email: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/send-password-reset-email`;
    const body = { email };
    return this.http.post<any>(url, body);
  }

  // Reset password
  resetPasswordWithEmail(token: string, password: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/api/auth/reset-password`;
    const body = { token, password };
    return this.http.post<any>(url, body);
  }

  // Check if user is authenticated
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const token = localStorage.getItem(LOCAL_STORAGE.accessToken);
      if (!token) {
        // this.router.navigate(["/signin"]);
        resolve(false);
      } else {
        try {
          const decoded: any = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);
          if (decoded.exp && decoded.exp >= currentTime) {
            resolve(true);
          } else {
            localStorage.removeItem(LOCAL_STORAGE.accessToken);
            resolve(false);
          }
        } catch (error) {
          localStorage.removeItem(LOCAL_STORAGE.accessToken);
          resolve(false);
        }
      }
    });
  }

  // Sign out
  signOut() {
    localStorage.removeItem(LOCAL_STORAGE.accessToken);
    this.router.navigate(["/signin"]);
  }
}