<div class="container">
  <div class="row justify-content-center">
    <div class="uploads-container">
      <div class="row cards">
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Podcast Details</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showPodcastDetails()"  routerLink="uploadPodcastDetails"></i>
          </div>
        </div>
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Podcast Episodes</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showPodcastEpisodes()"  routerLink="uploadPodcastEpisodes"></i>
          </div>
        </div>
        <div class="card col-4 m-3" style="width:15rem; height: 3rem;" >
          <div class="card-body d-flex align-items-center justify-content-between">
            <h6 class="card-title p-0 m-0">Podcast MetaData</h6>
            <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showPodcastMetaData()"  routerLink="uploadPodcastMetaData"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <app-podcast-details-uploads *ngIf="podcastDetailsState"></app-podcast-details-uploads>
  <app-podcast-episodes-uploads *ngIf="podcastEpisodesState"></app-podcast-episodes-uploads>
  <app-podcast-details-metadata *ngIf="podcastMetaDataState"></app-podcast-details-metadata>
</div>
