<div class=" upload-animation" *ngIf="showUploadAnimation">
  <button>Uploading...</button>
</div>
<div *ngIf="showUploadSuccessAnimation" class="upload-animation" >
  <button>Upload Successful</button>
</div>
<div *ngIf="showSaveSuccessAnimation" class="upload-animation" >
  <button>Data Was saved Successfully</button>
</div>
<div *ngIf="showSaveErrorAnimation" class="upload-animation">
  <button>{{this.message}}</button>
</div>
<div class="form-container p-2">
  <div class="top-title">
    <h4>Upload Podcast Details</h4>
  </div>
  <form class="form-content p-2" [formGroup]="showEpisodesForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="p-1 form-group">
      <label>Show Title</label>
      <input type="text" class="form-control" formControlName="showTitle" placeholder="Show Title">
      <div *ngIf="showEpisodesForm.controls['showTitle'].invalid && (showEpisodesForm.controls['showTitle'].dirty || showEpisodesForm.controls['showTitle'].touched || submitted)">
        <div class="formalerts" *ngIf="showEpisodesForm.controls['showTitle'].errors?.['required']">Show Title is required.</div>
        <div class="formalerts" *ngIf="showEpisodesForm.controls['showTitle'].errors?.['maxLength']">Show Title exceeds the maximum lenth of 50</div>
      </div>
    </div>
    <div class="p-1 form-group">
      <label>Season Number</label>
      <input type="number" class="form-control" formControlName="seasonNo" placeholder="Season Number">
      <div *ngIf="showEpisodesForm.controls['seasonNo'].invalid && (showEpisodesForm.controls['seasonNo'].dirty || showEpisodesForm.controls['seasonNo'].touched || submitted)">
        <div class="formalerts" *ngIf="showEpisodesForm.controls['seasonNo'].errors?.['required']">Season Number is required.</div>
      </div>
    </div>
    <div class="p-1 form-group">
      <label>Episode Title</label>
      <input type="text" class="form-control" formControlName="episodeTitle" placeholder="Episode Title">
      <div *ngIf="showEpisodesForm.controls['episodeTitle'].invalid && (showEpisodesForm.controls['episodeTitle'].dirty || showEpisodesForm.controls['episodeTitle'].touched || submitted)">
        <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeTitle'].errors?.['required']">Episode Title is required.</div>
        <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeTitle'].errors?.['maxLength']">Episode Title exceeds the maximum lenth of 50</div>
      </div>
    </div>

    <div class="p-1 form-group">
      <label>Episode Description</label>
      <textarea class="form-control" formControlName="episodeDesc" placeholder="Episode Description"></textarea>
      <div *ngIf="showEpisodesForm.controls['episodeDesc'].invalid && (showEpisodesForm.controls['episodeDesc'].dirty || showEpisodesForm.controls['episodeDesc'].touched || submitted)">
        <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeDesc'].errors?.['required']">Episode description is required.</div>
        <div class="formalerts" *ngIf="showEpisodesForm.controls['episodeDesc'].errors?.['maxLength']">Episode description exceeds the maximum lenth of 5000</div>
      </div>
    </div>

    <div class="p-1 form-group">
      <label>Episode</label>
      <input id="episode" type="file" class="form-control" formControlName="episode">
      <div *ngIf="showEpisodesForm.controls['episode'].invalid && (showEpisodesForm.controls['episode'].dirty || showEpisodesForm.controls['episode'].touched || submitted)">
        <div class="formalerts" *ngIf="showEpisodesForm.controls['episode'].errors?.['required']">Episode description is required.</div>
        <div class="formalerts" *ngIf="showEpisodesForm.controls['episode'].errors?.['pattern']">Wrong type of file, movie should have a valid extension(mp4, avi, mov)</div>
      </div>
    </div>

    <button class="my-2" type="submit">Submit</button>
  </form>
</div>
