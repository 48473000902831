import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from '../../services/content.service';
import { VideoModalComponent } from '../../components/video-modal/video-modal.component';
import { Media } from 'src/interfaces/player';
import { ContentItem } from 'src/interfaces/content';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit {
  familyContent: any[] = [];
  loading: boolean = true;

  constructor(
    private readonly contentService: ContentService,
    private readonly router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadFamilyContent();
  }

  loadFamilyContent() {
    this.contentService.getContentByTheme('Family').subscribe({
        next: (response) => {
          const content = response.content;
          this.familyContent = (content.movies ?? [])
            .concat(content.podcasts ?? [], content.shows ?? [])
            .sort((a: ContentItem, b: ContentItem) => {
              const dateA = new Date(a.datePublished ?? '');
              const dateB = new Date(b.datePublished ?? '');
              return dateA.getTime() - dateB.getTime();
            });
          this.loading = false;
        },
        error: () => {
          this.familyContent = [];
          this.loading = false;
        }
      }
    );
  }

  openVideoPlayer(content: any) {
    const media: Media = {
      src: content.movieUrl || content.podcastUrl || content.showUrl,
      title: content.title,
      description: content.description,
      type: content.type,
      // add other media properties if necessary
    };

    this.dialog.open(VideoModalComponent, {
      width: '80%',
      data: media
    });
  }

  navigateBack() {
    this.router.navigate(['/content']);
  }
}