import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, TimeoutError, timeout } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-show-episodes-uploads',
  templateUrl: './show-episodes-uploads.component.html',
  styleUrls: ['./show-episodes-uploads.component.scss']
})
export class ShowEpisodesUploadsComponent {
  showEpisodesForm!: FormGroup;
  episodeUploadObservable!: Observable<any>;
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message!: string;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private ContentService:ContentService) { }

  ngOnInit(): void {
    this.showEpisodesForm = this.formBuilder.group({
      showTitle: ['', [Validators.required, Validators.maxLength(50)]],
      seasonNo: ['', Validators.required],
      episodeTitle: ['', [Validators.required, Validators.maxLength(50)]],
      episodeDesc: ['', [Validators.required, Validators.maxLength(5000)]],
      episode: ['', [Validators.required, Validators.pattern('.+\\.(mp4|avi|mov)$')]],
    });
  }

  putSignedUrl(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({

      'Content-Type': 'application/octet-stream',
    });
    return this.http.put(signedUrl, file, { headers });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if(this.showEpisodesForm.valid){
      this.showUploadAnimation = true;
      const formData = new FormData();
      formData.append('showTitle', this.showEpisodesForm.get('showTitle')?.value || '');
      formData.append('seasonNo', this.showEpisodesForm.get('seasonNo')?.value || '');
      formData.append('episodeTitle', this.showEpisodesForm.get('episodeTitle')?.value || '');
      formData.append('episodeDesc', this.showEpisodesForm.get('episodeDesc')?.value || '');

      const episodeFile = document.getElementById('episode') as HTMLInputElement;
      if (episodeFile.files && episodeFile.files.length > 0) {
          const file = episodeFile.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          await new Promise<void>((resolve) =>{
          reader.onloadend = () => {
            formData.append('episode', file.name);
            resolve();
          };
        });
      }

      this.ContentService.postShowEpisodeSignedUrl(formData).subscribe(
        (response) => {
          const episode_signed_url = response.episode_signed_url;
          console.log(episode_signed_url)
          if (episodeFile && episodeFile.files && episodeFile.files.length > 0) {
            this.episodeUploadObservable = this.putSignedUrl(episode_signed_url, episodeFile.files[0]);
          }

          this.episodeUploadObservable.subscribe(
            () => {
              this.showUploadAnimation = false;
              this.showUploadSuccessAnimation = true;
              // Call the API to save the data
              this.ContentService.postShowEpiosodes(formData).pipe(timeout(60000)).subscribe(
                (response) => {
                  if(response.code === 201){
                    this.showUploadSuccessAnimation =false;
                    this.showSaveSuccessAnimation = true;
                    this.showEpisodesForm.reset();
                  }
                  else{
                    this.showUploadSuccessAnimation =false;
                    this.showSaveErrorAnimation = true;
                    this.message = response.message;
                  }
                },
                (error) => {
                  if (error instanceof TimeoutError) {
                    this.showUploadSuccessAnimation = false;
                    this.showSaveSuccessAnimation = true;
                    this.showEpisodesForm.reset();
                  } else {
                    this.showUploadSuccessAnimation = false;
                    this.showSaveErrorAnimation = true;
                    this.message = error;
                  }
                }
              );
            },
            (error) => {
              this.showSaveErrorAnimation = true;
              this.message = error;
            }
          );
        },
        (error) => {
          this.showSaveErrorAnimation = true;
          this.message = error;
        }
      );

    }
    else{
      this.showSaveErrorAnimation =  true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }

}
