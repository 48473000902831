import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-feed-details-upload',
  templateUrl: './feed-details-upload.component.html',
  styleUrls: ['./feed-details-upload.component.scss']
})
export class FeedDetailsUploadComponent {
  rssFeedForm!: FormGroup;

  constructor(private formBuilder:FormBuilder,private http: HttpClient,
    private ContentService: ContentService){}

  ngOnInit(): void{
    this.rssFeedForm = this.formBuilder.group({
      url : ['', Validators.required],

    })

  }

  async onSubmit(): Promise<void>{
    const formData = new FormData
    formData.append('url', this.rssFeedForm.get('url')?.value || '')


    this.ContentService.postRSSFeedUrl(formData).subscribe(
      response =>{
        console.log(response)
      },
      (error) => {
      console.log(error);
      }
    );}

}
