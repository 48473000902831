import { Component, ChangeDetectorRef, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subject, of } from 'rxjs';
import { takeUntil, map, shareReplay } from 'rxjs/operators';
import { ContentItem, AllData } from 'src/interfaces/content';
import { ContentState } from '../store/state/content.state';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionService } from '../services/subscription.service';
import { PaymentPlanService } from '../services/payment-plan.service';
import { Subscription } from 'src/interfaces/subscription';
import { SubscriptionModalComponent } from '../components/subscription-modal/subscription-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  checkAuthentication,
  loadContent,
  setSelectedContentId,
  setSelectedContentType,
  setSelectedContent
} from '../store/actions/content.actions';
import {
  loadSubscriptions,
} from '../store/actions/subscription.actions';
import {
  selectAllMovies,
  selectAllShows,
  selectAllPodcasts,
  selectContentLoading,
  selectContentError,
  selectContentLoaded,
} from '../store/selectors/content.selectors';
import {
  selectSubscriptions,
  selectSubscriptionLoading,
  selectSubscriptionError,
  selectSubLoaded
} from '../store/selectors/subscription.selectors';
import { jwtDecode } from 'jwt-decode';
import { ContentService } from '../services/content.service';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
  moviesData$: Observable<ContentItem[]>;
  podcasts$: Observable<ContentItem[]>;
  shows$: Observable<ContentItem[]>;
  isLoading$: Observable<boolean>;
  error$: Observable<string | null>;
  isLoaded$: Observable<boolean>;
  subscriptionData$: Observable<Subscription[]>;
  subscriptionLoading$: Observable<boolean>;
  subscriptionError$: Observable<string | null>;
  subLoaded$: Observable<boolean>;
  isContentLoaded$: Observable<boolean>;

  randomPost!: { key: string, data: any };
  allData: AllData = { movies: [], shows: [], podcasts: [] };
  isPaid!: boolean;
  currentContentType: string = '';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly store: Store<ContentState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly subscriptionService: SubscriptionService,
    private readonly paymentPlanService: PaymentPlanService,
    private readonly spinner: NgxSpinnerService,
    private readonly contentService: ContentService,
    private readonly searchService: SearchService
  ) {
    this.moviesData$ = this.store.select(selectAllMovies).pipe(shareReplay(1));
    this.podcasts$ = this.store.select(selectAllPodcasts).pipe(shareReplay(1));
    this.shows$ = this.store.select(selectAllShows).pipe(shareReplay(1));
    this.isLoading$ = this.store.select(selectContentLoading).pipe(shareReplay(1));
    this.error$ = this.store.select(selectContentError).pipe(shareReplay(1));
    this.isLoaded$ = this.store.select(selectContentLoaded).pipe(shareReplay(1));
    this.subscriptionLoading$ = this.store.select(selectSubscriptionLoading).pipe(shareReplay(1));
    this.subscriptionError$ = this.store.select(selectSubscriptionError).pipe(shareReplay(1));
    this.subLoaded$ = this.store.select(selectSubLoaded).pipe(shareReplay(1));

    this.subscriptionData$ = this.store.select(selectSubscriptions).pipe(
      map((response: any) => response.data as Subscription[]),
      shareReplay(1)
    );

    this.isContentLoaded$ = combineLatest([
      this.moviesData$,
      this.podcasts$,
      this.shows$
    ]).pipe(
      map(([movies, podcasts, shows]) => movies.length > 0 || podcasts.length > 0 || shows.length > 0),
      shareReplay(1)
    );
  }

  @Input() searchQuery: string = '';

  ngOnInit() {
    this.spinner.show();
    this.store.dispatch(checkAuthentication());
    this.checkSubscription();

    this.searchService.queryChanges.subscribe(({ contentType, filter }) => {
      this.filterContent(contentType, filter);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private filterContent(contentType: string, filterObj: { [key: string]: any }): void {
    if (Object.keys(filterObj).length > 0) {
      this.currentContentType = contentType;
      this.contentService.filter(contentType, filterObj).subscribe({
        next: (response) => {
          this.allData = response;
          this.updateFilteredContent(contentType, response.content);
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.loadContentData();
          console.error(err);
        }
      });
    } else {
      this.currentContentType = '';
      this.loadContentData();
    }
  }

  private updateFilteredContent(contentType: string, content: ContentItem[]): void {
    switch (contentType) {
      case 'movies':
        this.moviesData$ = of(content);
        break;
      case 'shows':
        this.shows$ = of(content);
        break;
      case 'podcasts':
        this.podcasts$ = of(content);
        break;
      default:
        break;
    }
  }

  private checkSubscription(): void {
    const token = sessionStorage.getItem('2lietkapas');
    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        const userId = decodedToken.id;

        if (userId) {
          this.checkSubscriptionStatus(userId);
        } else {
          console.error('User ID not found in decoded token');
        }
      } catch (error) {
        console.error('Invalid token:', error);
      }
    } else {
      console.error('Token not found in localStorage');
    }
  }

  private checkSubscriptionStatus(userId: string): void {
    this.subLoaded$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subLoaded) => {
        if (!subLoaded) {
          this.loadSubscriptions(userId);
        } else {
          this.handleSubscriptionData();
        }
      });
  }

  private loadSubscriptions(userId: string): void {
    this.store.dispatch(loadSubscriptions({ userId }));
  }

  private handleSubscriptionData(): void {
    this.subscriptionData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscriptions) => {
        const activeSubscription = subscriptions.find((sub: Subscription) => sub.activeStatus === true);
        this.isPaid = !!activeSubscription;

        if (this.isPaid) {
          this.loadContentData();
        } else {
          this.openSubscriptionModal();
        }
      });
  }

  private loadContentData(): void {
    this.isLoaded$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoaded) => {
        if (!isLoaded) {
          this.store.dispatch(loadContent());
        }
      });

    combineLatest([
      this.moviesData$,
      this.podcasts$,
      this.shows$
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([movies, podcasts, shows]) => {
        this.allData.movies = this.sortByDatePublished(movies);
        this.allData.podcasts = this.sortByDatePublished(podcasts);
        this.allData.shows = this.sortByDatePublished(shows);
        this.randomPost = this.getRandomPost(this.allData);
        this.spinner.hide();
        this.cdr.detectChanges();
      });

    this.isLoading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoading) => {
        if (isLoading) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
        this.cdr.detectChanges();
      });
  }

  private sortByDatePublished(content: ContentItem[]): ContentItem[] {
    return content.slice().sort((a, b) => new Date(a.datePublished).getTime() - new Date(b.datePublished).getTime());
  }

  public openSubscriptionModal(): void {
    this.paymentPlanService.getAllPlans().subscribe({
      next: (plans) => {
        this.openDialog(plans);
      },
      error: (error) => {
        console.error('Error fetching plans:', error);
        this.openDialog([]);
      }
    });
  }

  private openDialog(plans: any[]): void {
    const dialogRef = this.dialog.open(SubscriptionModalComponent, {
      width: '800px',
      height: 'auto',
      maxHeight: '90vh',
      maxWidth: '90vw',
      panelClass: 'custom-dialog-container',
      data: { isPaid: this.isPaid, plans }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'subscribed') {
        this.isPaid = true;
        this.loadContentData();
      }
    });
  }

  onItemClick(item: ContentItem) {
    const contentType = this.getContentType(item);

    const contentId = item.contentId ?? 0;
    this.store.dispatch(setSelectedContentId({ contentId }));
    this.store.dispatch(setSelectedContentType({ contentType }));
    this.store.dispatch(setSelectedContent({ content: item }));
    this.router.navigate([`/${contentType}/${item.contentId}`]);
  }

  private getContentType(item: ContentItem): string {
    if (item.movieUrl) {
      return 'movies';
    } else if (item.showUrl) {
      return 'shows';
    } else if (item.podcastUrl) {
      return 'podcasts';
    }
    return '';
  }

  private getRandomPost(allData: AllData): { key: string, data: any } {
    if (!this.isPaid) {
      return { key: '', data: null };
    }
    const allDataKeys = ['movies', 'shows', 'podcasts'];
    const randomArrayKey = allDataKeys[Math.floor(Math.random() * allDataKeys.length)];
    const randomArray = allData[randomArrayKey as keyof AllData] || [];
    
    if (randomArray.length === 0) {
      return { key: randomArrayKey, data: null };
    }
    
    const randomPost = {
      key: randomArrayKey,
      data: randomArray[Math.floor(Math.random() * randomArray.length)],
    };
    
    // If only one item, keep returning that item
    if (randomArray.length === 1) {
      randomPost.data = randomArray[0];
    }
    
    return randomPost;
  }

  public fetchNewRandomPost(): void {
    this.randomPost = this.getRandomPost(this.allData);
    this.cdr.detectChanges();
  }
}