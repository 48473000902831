import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsPaidService {

  constructor() {}
  private isPaid!: boolean;

  setPaidStatus(value: boolean) {
    this.isPaid = value;
  }

  getPaidStatus(): boolean {
    return this.isPaid;
  }
}
