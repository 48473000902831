import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
  networkErrorMessage: string = '';
  isLoading: Boolean = false;
  actionCode: string = '';

  constructor(
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.actionCode = this.route.snapshot.queryParams['oobCode'];
  }

  getErrorMessage() {
      if (this.emailFormControl?.hasError('required') || this.emailFormControl?.hasError('email') || this.emailFormControl?.hasError('pattern'))
        return 'Please enter a valid email';
      else return
    }

  async sendResetPasswordLink() {
    if (this.emailFormControl.valid && this.passwordFormControl.valid) {
      this.isLoading = true;
      this.auth.resetPasswordWithEmail(this.actionCode, this.passwordFormControl.value || '').subscribe({
        next: (response) => {
          if (response.success) {
            this.router.navigate(['/signin']);
          } else {
            if (response.error) {
              switch (response.error.code) {
                case 'auth/expired-action-code':
                  this.networkErrorMessage = 'The action code has expired.';
                  break;
                case 'auth/invalid-action-code':
                  this.networkErrorMessage = 'The action code is invalid.';
                  break;
                default:
                  this.networkErrorMessage = 'Oops! Something went wrong.';
                  console.error(response.error.code);
                  break;
              }
            }
          }
          this.isLoading = false;
        },
        error: (err) => {
          // Handle any errors that occur during the request
          console.error(err);
          this.isLoading = false;
        }
      });
    }
  }
}