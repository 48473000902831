import { createAction, props } from '@ngrx/store';
import { ContentItem } from 'src/interfaces/content';

export const checkAuthentication = createAction('[Content] Check Authentication');
export const loadContent = createAction('[Content] Load Content');
export const loadContentSuccess = createAction(
    '[Content] Load Content Success',
    props<{ movies: ContentItem[], shows: ContentItem[], podcasts: ContentItem[] }>()
);
export const loadContentFailure = createAction(
    '[Content] Load Content Failure',
    props<{ error: string }>()
);

export const selectContent = createAction(
    '[Content] Select Content',
    props<{ contentId: number, contentType: string }>()
);

export const selectContentSuccess = createAction(
    '[Content] Select Content Success',
    props<{ content: ContentItem }>()
);

export const selectContentError = createAction(
    '[Content] Select Content Error',
    props<{ error: string }>()
);

export const setSelectedContentId = createAction(
    '[Content] Set Content ID',
    props<{ contentId: number }>()
);

export const setSelectedContentType = createAction(
    '[Content] Set Content Type',
    props<{ contentType: string }>()
);

export const setSelectedContent = createAction(
    '[Content] Set Selected Content',
    props<{ content: ContentItem }>()
);

export const resetState = createAction('[Content] Reset State');