import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {
  constructor() { }

  private selectedTheme = new BehaviorSubject<string>('');

  setSelectedTheme(theme: string) {
    this.selectedTheme.next(theme);
  }

  getSelectedTheme() {
    return this.selectedTheme.asObservable();
  }
}
