<div class=" upload-animation" *ngIf="showUploadAnimation">
  <button>Uploading...</button>
</div>
<div [@uploadAnimation]="showUploadSuccessAnimation ? '' : 'void'" class="upload-success-animation" >
  <button>Upload Successful</button>
</div>
<div *ngIf="showSaveSuccessAnimation" class="upload-animation" >
  <button>Data Was saved Successfully</button>
</div>
<div *ngIf="showSaveErrorAnimation" class="upload-animation">
  <button>{{this.message}}</button>
</div>
<div class="card col-4 m-3" style="width:15rem; height: 3rem;">
  <div class="card-body d-flex align-items-center justify-content-between">
    <h6 class="card-title p-0 m-0">Movie MetaData</h6>
    <i class="fa fa-lg fa-plus fa-bold pl-2" aria-hidden="true" (click)="showMovieMetadata()"  routerLink="uploadMovieMetaData"></i>
  </div>
</div>

<div class="form-container p-2" *ngIf="!showMovieMetadataForm">
  <div class="top-title">
    <h4>Upload A Movie</h4>
  </div>
  <form class="form-content p-2" [formGroup]="movieForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="p-1 form-group">
      <label>Movie Title</label>
      <input type="text" class="form-control" formControlName="title" placeholder="Title" required>
      <div *ngIf="movieForm.controls['title'].invalid && (movieForm.controls['title'].dirty || movieForm.controls['title'].touched || submitted)">
        <div class="formalerts" *ngIf="movieForm.controls['title'].errors?.['required']">Title is required.</div>
        <div class="formalerts" *ngIf="movieForm.controls['title'].errors?.['maxLength']">Title exceeds the maximum lenth of 50</div>
      </div>
    </div>

    <div class="p-1 form-group">
      <label>Movie Description</label>
      <textarea class="form-control" formControlName="movieDesc" placeholder="Movie Description"></textarea>
      <div *ngIf="movieForm.controls['movieDesc'].invalid && (movieForm.controls['movieDesc'].dirty || movieForm.controls['movieDesc'].touched || submitted)">
        <div class="formalerts" *ngIf="movieForm.controls['movieDesc'].errors?.['required']">Movie description is required.</div>
        <div class="formalerts" *ngIf="movieForm.controls['movieDesc'].errors?.['maxLength']">Movie desc exceeds the maximum length of 5000 </div>
      </div>
    </div>
    <div class="p-1 form-group">
      <label>Image File</label>
      <input id="image" type="file" class="form-control" formControlName="image" placeholder="Image">
      <div *ngIf="movieForm.controls['image'].invalid && (movieForm.controls['image'].dirty || movieForm.controls['image'].touched || submitted)">
        <div class="formalerts" *ngIf="movieForm.controls['image'].errors?.['required']">Image is required.</div>
        <div class="formalerts" *ngIf="movieForm.controls['image'].errors?.['pattern']">Wrong type of file, image should have a valid extension(jpg,jpeg, png)</div>
      </div>
    </div>
    <div class="p-1 form-group">
      <label>Movie File</label>
      <input id="movie" type="file" class="form-control" formControlName="movie" placeholder="Movie">
      <div *ngIf="movieForm.controls['movie'].invalid && (movieForm.controls['movie'].dirty || movieForm.controls['movie'].touched || submitted)">
        <div class="formalerts" *ngIf="movieForm.controls['movie'].errors?.['required']">Movie is required.</div>
        <div class="formalerts" *ngIf="movieForm.controls['movie'].errors?.['pattern']">Wrong type of file, movie should have a valid extension(mp4, avi, mov)</div>
      </div>
    </div>
    <div class="shorter-width p-1 form-group">
      <label for="contentTheme">Content Theme</label>
      <select id="contentTheme" class="form-control" formControlName="contentTheme">
        <option value="Family">Family</option>
        <option value="Kids">Kids</option>
        <option value="Animation">Animation</option>
        <option value="Lifestyle">Lifestyle</option>
      </select>
      <div *ngIf="movieForm.controls['contentTheme'].invalid && (movieForm.controls['contentTheme'].dirty || movieForm.controls['contentTheme'].touched || submitted)">
        <div *ngIf="movieForm.controls['contentTheme'].errors?.['required']">Content theme is required.</div>
      </div>
    </div>
    <button class="my-2" type="submit">Submit</button>
  </form>

</div>
<div *ngIf="showMovieMetadataForm">
  <router-outlet></router-outlet>
</div>
