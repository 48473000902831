import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { SigninComponent } from './signin/signin.component';
import { ContentComponent } from './content/content.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { PlayerComponent } from './player/player.component';
import { HomeComponent } from './home/home.component';
import { SplashComponent } from './splash/splash.component';
import { MoviesComponent } from './movies/movies.component';
import { PodcastsComponent } from './podcasts/podcasts.component';
import { PayComponent } from './pay/pay.component';
import { SeriesComponent } from './series/series.component';
import { ThemesComponent } from './themes/themes.component';
import { AuthenticationService } from './services/authentication.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsNConditionsComponent } from './terms-n-conditions/terms-n-conditions.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { PaymentService } from './services/payment.service';
import { DashboardComponent } from './admin/views/dashboard/dashboard.component';
import { UploadsComponent } from './admin/views/uploads/uploads.component';
import { AdminComponent } from './admin/admin.component';
import { AdminService } from './services/admin.service';
import { MoviesUploadsComponent } from './components/movies-uploads/movies-uploads.component';
import { MovieMetadataComponent } from './components/movie-metadata/movie-metadata.component';
import { PodcastDetailsUploadsComponent } from './components/podcast-details-uploads/podcast-details-uploads.component';
import { PodcastEpisodesUploadsComponent } from './components/podcast-episodes-uploads/podcast-episodes-uploads.component';
import { PodcastUploadsComponent } from './components/podcast-uploads/podcast-uploads.component';
import { PodcastDetailsMetadataComponent } from './components/podcast-details-metadata/podcast-details-metadata.component';
import { ShowDetailsUploadsComponent } from './components/show-details-uploads/show-details-uploads.component';
import { ShowEpisodesUploadsComponent } from './components/show-episodes-uploads/show-episodes-uploads.component';
import { ShowDetailsMetadataComponent } from './components/show-details-metadata/show-details-metadata.component';
import { ShowUploadsComponent } from './components/show-uploads/show-uploads.component';
import { SeasonDetailsUploadsComponent } from './components/season-details-uploads/season-details-uploads.component';
import { FeedDetailsUploadComponent } from './components/feed-details-upload/feed-details-upload.component';
import { FilterComponent } from './filter/filter.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  { path: '', component: SplashComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationService],
  },
  { path: 'signup', component: SignupComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'content', component: ContentComponent, canActivate: [AuthenticationService]},
  { path: 'verify-email', component: EmailConfirmationComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'update-password', component: UpdatePasswordComponent },
  { path: 'player', component: PlayerComponent, canActivate: [PaymentService] },
  {
    path: 'player/:id',
    component: PlayerComponent,
    canActivate: [PaymentService],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'player/:movieSlug/:id',
    component: PlayerComponent,
    canActivate: [PaymentService],
  },
  {
    path: 'player/:showSlug/:seasonSlug/:id',
    component: PlayerComponent,
    canActivate: [PaymentService],
  },
  { path: 'splash', component: SplashComponent },
  { path: 'movie/:id', component: MoviesComponent },
  { path: 'podcast/:id', component: PodcastsComponent },
  {
    path: 'pay',
    component: PayComponent,
    canActivate: [AuthenticationService],
  },
  {
    path: 'podcast/:showSlug/:seasonSlug/:episodeSlug',
    component: PodcastsComponent,
  },
  { path: 'show/:id', component: SeriesComponent },
  { path: 'themes/:selectedTheme', component: ThemesComponent },
  { path: 'filter', component: FilterComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AdminService] },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminService],
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'uploads',
        component: UploadsComponent,
        children: [
          {
            path: 'uploadMovies',
            component: MoviesUploadsComponent,
            children: [
              {
                path: 'uploadMovieMetaData',
                component: MovieMetadataComponent,
              },
            ],
          },
          {
            path: 'uploadPodcasts',
            component: PodcastUploadsComponent,
            children: [
              {
                path: 'uploadPodcastDetails',
                component: PodcastDetailsUploadsComponent,
              },
              {
                path: 'uploadPodcastEpisodes',
                component: PodcastEpisodesUploadsComponent,
              },
              {
                path: 'uploadPodcastMetaData',
                component: PodcastDetailsMetadataComponent,
              },
            ],
          },
          {
            path: 'uploadShows',
            component: ShowUploadsComponent,
            children: [
              {
                path: 'uploadShowDetails',
                component: ShowDetailsUploadsComponent,
              },
              {
                path: 'uploadSeasonDetails',
                component: SeasonDetailsUploadsComponent,
              },
              {
                path: 'uploadShowEpisodes',
                component: ShowEpisodesUploadsComponent,
              },
              {
                path: 'uploadShowMetaData',
                component: ShowDetailsMetadataComponent,
              },
            ],
          },
          { path: 'uploadRSSFeeds', component: FeedDetailsUploadComponent },
        ],
      },
    ],
  },

  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms-n-conditions', component: TermsNConditionsComponent },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
