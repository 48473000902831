<div class="splash">
    <header class="header">
        <app-light-logo></app-light-logo>
        <nav class="navigation">
            <a routerLink="/signin">
                <app-inactive-btn class="btn--sign-in" [buttonAction]="signInBtnLabel"></app-inactive-btn>
            </a>
            <a routerLink="/signup">
                <app-active-btn class="btn--sign-up highlighted" [buttonAction]="signUpBtnLabel"></app-active-btn>
            </a>
        </nav>
    </header>
    <main class="content">
        <section class="welcome-message">
            <h1>Entertainment for the Whole Family</h1>
            <p>Explore movies, shows, and podcasts that bring joy to every member of your family.</p>
            <a routerLink="/signup">
                <app-active-btn class="signup-btn highlighted" [buttonAction]="signUpBtnLabel"></app-active-btn>
            </a>
        </section>
        <section class="options">
            <app-splash-card *ngFor="let item of cardItems; index as i" [cardItem]="item"></app-splash-card>
        </section>
    </main>
    <footer class="footer">
        <section class="footer-nav">
            <a routerLink="/about">About Us</a>
            <a routerLink="/contact">Contact Us</a>
            <a routerLink="/privacy">Privacy Policy</a>
        </section>
        <section class="copyright">
            <p>&copy; {{ currentYear }} NWPLUS TV. All rights reserved.</p>
        </section>
    </footer>
</div>