<div class="container-fluid">
  <app-top-content [contentData]="podcastData" [isPaid]="isPaid"></app-top-content>
  <div class=" row episode my-4 p-3" *ngFor="let episode of podcast.episodes || podcast.feeds">
    <div class="col-4 podcastImage">
      <img src="{{podcast.image}}" />
    </div>
    <div class="col-8">
      <div class="row">
        <h4>Episode: {{episode.episodeTitle}}</h4>
        <p>{{episode.episodeDesc}}</p>
      </div>
      <div class="row">
        <audio [controls]="" [src]="episode.episode" controls></audio>
      </div>

    </div>
  </div>
</div>


