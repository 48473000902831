import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Media } from 'src/interfaces/player';
const logo =  '../../assets/logo.png';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent {

  public fileSrc!: string;
  playerOptions!: Plyr.Options;
  media!: Media;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.media = this.router.getCurrentNavigation()?.extras.state?.['media']
  }
};
