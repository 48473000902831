<app-header></app-header>
<div class="container-fluid ">

  <div class="content" *ngIf="content">

    <div *ngIf="content.data.movies.length > 0">
      <h3 class="py-3">Movies</h3>
      <app-slider [contentData]="content.data.movies" routerLink="/movie" ></app-slider>
    </div>

    <div *ngIf="content.data.podcasts.length > 0">
      <h3 class="py-3">Podcasts</h3>
      <app-slider [contentData]="content.data.podcasts" routerLink="/podcast" ></app-slider>
    </div>

    <div *ngIf="content.data.shows.length > 0">
      <h3 class="py-3">Shows</h3>
      <app-slider [contentData]="content.data.shows" routerLink="/show" ></app-slider>
    </div>
  </div>
