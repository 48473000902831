<div class="dashboard-container">
    <main class="dark">
        <div class="head-title">
            <div class="left">
                <h1>Dashboard</h1>
                <ul class="breadcrumb">
                    <li>
                        <a routerLink="/admin">Dashboard</a>
                    </li>
                    <li><i class='bx bx-chevron-right'></i></li>
                    <li>
                        <a class="active" routerLink="/admin">Home</a>
                    </li>
                </ul>
            </div>

        </div>

        <ul class="box-info">
            <li>
                <i class='bx bxs-calendar-check'></i>
                <span class="text">
                    <h3>{{activeSubscribers.length}}</h3>
                    <p>Subscribers</p>
                </span>
            </li>
            <li>
                <i class='bx bxs-group'></i>
                <span class="text">
                    <h3>{{activeSubscribers.length +
                        inactiveSubscribers.length}}</h3>
                    <p>Visitors</p>
                </span>
            </li>
            <li>
                <i class='bx bxs-dollar-circle'></i>
                <span class="text">
                    <h3>Ksh. -</h3>
                    <p>Total Sales</p>
                </span>
            </li>
        </ul>


        <div class="table-data">
            <div class="order">
                <div class="head">
                    <h3>Subscriptions</h3>
                    <i class='bx bx-search'></i>
                    <i class='bx bx-filter'></i>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="users.length > 0">
                        <tr *ngFor="let subscriber of activeSubscribers">
                            <td>
                                <img src="../../../../assets/logo.png">
                                <p>{{subscriber["user"]["username"]}}</p>
                            </td>
                            <td>
                                <span class="status active"
                                    *ngIf="subscriber['payment_info']['active_status']">active</span>
                                <span class="status inactive"
                                    *ngIf="!subscriber['payment_info']['active_status']">inactive</span>
                            </td>
                        </tr>
                        <tr *ngFor="let subscriber of inactiveSubscribers">
                            <td>
                                <img src="../../../../assets/logo.png">
                                <p>{{subscriber["user"]["username"]}}</p>
                            </td>
                            <td>
                                <span class="status inactive">inactive</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="todo">
                <div class="head">
                    <h3>Subscribers</h3>
                    <i class='bx bx-plus'></i>
                    <i class='bx bx-filter'></i>
                </div>
                <ul class="todo-list" *ngIf="users.length > 0">
                    <li class="active"
                        *ngFor="let subscriber of activeSubscribers">
                        <img src="../../../../assets/logo.png" class="profile">
                        <span>{{subscriber["user"]["username"]}}</span>
                        <i class='bx bx-dots-vertical-rounded'></i>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</div>