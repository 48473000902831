import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ContentService } from '../services/content.service';
import { Subscribable, Subscription, filter, forkJoin, switchMap, take } from 'rxjs';
import { ThemesService } from '../services/themes.service';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss']
})

export class ThemesComponent implements OnInit, OnDestroy {
  selectedTheme!: string;
  content: any;
  allData: any;
  randomPost: any;
  themeSub!: Subscription;
  randomSub!:Subscription;
  contentSub!: Subscription;

  constructor(private router: Router, private themeService: ThemesService, private contentService: ContentService) { }

  ngOnInit() {
    console.log("initialized")
    this.themeSub = this.themeService.getSelectedTheme().subscribe((theme) => {
      this.selectedTheme = theme;
      console.log(this.selectedTheme)
      this.getThemes();
      this.getRandomContent();
    });
  }

  ngOnDestroy() {
    this.themeSub.unsubscribe();
    this.randomSub.unsubscribe();
    this.contentSub.unsubscribe();

  }

  getRandomContent() {
    this.randomPost = null;
    if (this.randomSub) {
      this.randomSub.unsubscribe();
    }
    this.randomSub = this.contentService.getContentByTheme(this.selectedTheme).subscribe((response: any) => {
      this.allData = {
        movies: response.data.movies,
        shows: response.data.shows,
      };
      const allDataKeys = Object.keys(this.allData);
      let randomArrayKey, randomArray, Post;
      let emptyCount = 0;
      do {

        randomArrayKey = allDataKeys[Math.floor(Math.random() * allDataKeys.length)];
        randomArray = this.allData[randomArrayKey];
        if (randomArray.length > 0) {
          Post = randomArray[Math.floor(Math.random() * randomArray.length)];
        } else {
          emptyCount++;
        }
      } while (!Post && emptyCount < allDataKeys.length);
      if (!Post) {
        // handle the case where all categories are empty
        console.error('No content available for any category');
        return;
      }
      this.randomPost = { key: randomArrayKey, data: Post };
    });

  }


  getThemes() {
    if (this.contentSub) {
      this.contentSub.unsubscribe();
    }
    if (this.randomSub) {
      this.randomSub.unsubscribe();
    }
    this.contentSub = this.contentService.getContentByTheme(this.selectedTheme)
      .pipe(
        take(1)
      )
      .subscribe(response => {
        this.content = response.data;
        console.log(this.content);
      });
  }

}
