import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-season-details-uploads',
  templateUrl: './season-details-uploads.component.html',
  styleUrls: ['./season-details-uploads.component.scss']
})
export class SeasonDetailsUploadsComponent {
  seasonDetailsForm!: FormGroup;
  imageUploadObservable!: Observable<any>;
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message!: string;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private ContentService:ContentService) { }

  ngOnInit(): void {
    this.seasonDetailsForm = this.formBuilder.group({
      showTitle: ['', [Validators.required, Validators.maxLength(50)]],
      seasonNo: ['', Validators.required],
      desc: ['', [Validators.required, Validators.maxLength(5000)]],
      image: ['', [Validators.required, Validators.pattern('.+\\.(jpg|jpeg|png)$')]]
    });
  }

  putSignedUrl(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({

      'Content-Type': 'application/octet-stream',
    });
    return this.http.put(signedUrl, file, { headers });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if(this.seasonDetailsForm.valid){
      this.showUploadAnimation = true;
      const formData = new FormData();
      formData.append('showTitle', this.seasonDetailsForm.get('showTitle')?.value || '');
      formData.append('no', this.seasonDetailsForm.get('seasonNo')?.value || '');
      formData.append('desc', this.seasonDetailsForm.get('podcastDesc')?.value || '');

      const imageFile = document.getElementById('image') as HTMLInputElement;
      if (imageFile.files && imageFile.files.length > 0) {
          const file = imageFile.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          await new Promise<void>((resolve) =>{
          reader.onloadend = () => {
            formData.append('image', file.name);
            resolve();
          };
        });
      }

      formData.append('contentTheme',this.seasonDetailsForm.get('contentTheme')?.value || '')

      this.ContentService.postSeasonDetailSignedUrl(formData).subscribe(
        (response) => {
          console.log(response);
          const image_signed_url = response.image_signed_url;
          console.log(image_signed_url)
          if (imageFile && imageFile.files && imageFile.files.length > 0) {
            this.imageUploadObservable = this.putSignedUrl(image_signed_url, imageFile.files[0]);
          }

          this.imageUploadObservable.subscribe(
            () => {
              this.showUploadAnimation = false;
              this.showUploadSuccessAnimation = true;

              // Call the API to save the data
              this.ContentService.postSeasonDetails(formData).subscribe(
                (response) => {
                  if(response.code === 201){
                    this.showUploadSuccessAnimation =false;
                    this.showSaveSuccessAnimation =true;
                    this.seasonDetailsForm.reset();
                  }
                  else{
                    this.showUploadSuccessAnimation =false;
                    this.showSaveErrorAnimation = true;
                    this.message = response.message;
                  }
                },
                (error) => {
                  this.showSaveErrorAnimation = true;
                  this.message = error;
                }
              );
            },
            (error) => {
              this.showSaveErrorAnimation = true;
              this.message = error;
            }
          );
        },
        (error) => {
          this.showSaveErrorAnimation = true;
          this.message = error;
        }
      );
    }
    else{
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }
}
