<div class="container-fluid ">
  <app-top-content [contentData]="randomPost" [isPaid]="isPaid"></app-top-content>
  <div class="content">
    <h3 class="py-3">Movies</h3>
    <app-slider [contentData]="moviesData" routerLink="/movie" ></app-slider>

    <h3 class="py-3">Podcasts</h3>
    <app-slider [contentData]="podcasts" routerLink="/podcast" ></app-slider>

    <h3 class="py-3">Shows</h3>
    <app-slider [contentData]="shows" routerLink="/show" ></app-slider>
  </div>



</div>