import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToggleDescriptionService } from 'src/app/services/toggle-description.service';

@Component({
  selector: 'app-content-description',
  templateUrl: './content-description.component.html',
  styleUrls: ['./content-description.component.scss']
})
export class ContentDescriptionComponent {
  @Input() contentData!: { key: string, data: any };
  constructor(public toggleDesc: ToggleDescriptionService, private router: Router){
  }

  ngOnInit(){

  }

  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }

  onInfoButtonClick(contentData: any) {
    console.log(contentData);

    if (contentData.key === 'movies') {
      this.router.navigate(['/movie', this.encodeUrl(contentData.data.slug)]);
    } else if (contentData.key === 'shows') {
      this.router.navigate(['/show', this.encodeUrl(contentData.data.slug)]);
    } else if (contentData.key === 'podcasts') {
      this.router.navigate(['/podcast', this.encodeUrl(contentData.data.slug)]);
    }
  }

}
