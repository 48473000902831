import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchResults: any;
  private querySubject: Subject<string> = new Subject<string>();
  public queryChanges: Observable<string> = this.querySubject.asObservable();

  constructor() { }

  setSearchResults(results: any): void {
    this.searchResults = results;
    this.querySubject.next(results);
  }

  getSearchResults(): any {
    return this.searchResults;
  }
}
