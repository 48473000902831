import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import Plyr from 'plyr';
import Hls from 'hls.js';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() media: Media | undefined;
  @Input() playlist: Media[] = [];
  @ViewChild('videoRef') videoRef!: ElementRef<HTMLVideoElement>;
  player!: Plyr;
  private hls!: Hls;
  private currentIndex: number = 0;
  private qualityLevels: Array<{ label: string; value: number }> = [];
  message: string = '';
  private playerInitialized: boolean = false;
  private previousMediaSrc: string | undefined;

  constructor(private readonly router: Router, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.media) {
      console.error('No media input provided');
    }
  }

  ngAfterViewInit() {
    if (this.media && !this.playerInitialized) {
      this.initializePlayer();
      this.playerInitialized = true;
      this.previousMediaSrc = this.media.src;
    }
  }

  ngOnDestroy() {
    this.cleanupPlayer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['media'] && !changes['media'].firstChange) {
      if (this.media && this.media.src !== this.previousMediaSrc) {
        this.initializePlayer();
        this.previousMediaSrc = this.media.src;
      }
    }
  }

  private initializePlayer() {
    const videoElement = this.videoRef?.nativeElement;
    if (!videoElement) {
      console.error('No video element found');
      return;
    }

    if (this.media?.src.endsWith('.m3u8')) {
      this.setupHls(videoElement);
    } else {
      this.setupNonHls(videoElement);
    }
    this.setupPlyr(videoElement);
    this.addEventListeners(videoElement);
    this.cdr.markForCheck();
  }

  private setupPlyr(videoElement: HTMLVideoElement) {
    const options: Plyr.Options = {
      captions: { active: true, update: true, language: 'auto' },
      autoplay: true,
      controls: [
        'play-large', 'restart', 'rewind', 'play', 'fast-forward', 'progress',
        'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'fullscreen'
      ],
      settings: ['captions', 'quality', 'speed'],
      quality: {
        default: 720,
        options: [360, 480, 720, 1080],
        forced: true,
        onChange: (quality: number) => this.changeQuality(quality)
      },
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.25, 1.5, 2]
      }
    };

    this.player = new Plyr(videoElement, options);

    this.player.on('ended', () => this.handleVideoEnd());
  }

  private setupHls(videoElement: HTMLVideoElement) {
    if (Hls.isSupported()) {
      this.hls = new Hls({
        capLevelToPlayerSize: true,
        autoStartLoad: true,
        startLevel: -1,
        enableWorker: true
      });

      if (this.media?.src) {
        this.hls.loadSource(this.media.src);
      } else {
        console.error('Invalid media source');
      }

      this.hls.attachMedia(videoElement);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.setupQualityLevels();
        this.playVideo();
      });

      this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
        if (data.level === -1) {
          this.message = 'Auto quality selected';
          this.cdr.detectChanges();
          this.clearMessageAfterTimeout();
        }
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      if (this.media?.src) {
        videoElement.src = this.media.src;
      } else {
        console.error('Invalid media source');
      }
      videoElement.addEventListener('loadedmetadata', () => this.playVideo());
    } else {
      console.error('HLS is not supported in this browser');
    }
  }

  private setupNonHls(videoElement: HTMLVideoElement) {
    if (this.media?.src) {
      videoElement.src = this.media.src;
      this.message = 'This video has only one quality available.';
      this.cdr.detectChanges();
      this.clearMessageAfterTimeout();
    } else {
      console.error('Invalid media source');
    }
    videoElement.addEventListener('loadedmetadata', () => this.playVideo());
  }

  private changeQuality(newQuality: number) {
    if (this.hls) {
      const currentTime = this.videoRef.nativeElement.currentTime;
      this.hls.currentLevel = newQuality;
      this.hls.on(Hls.Events.LEVEL_SWITCHED, () => {
        this.videoRef.nativeElement.currentTime = currentTime;
        this.playVideo();
      });
    } else {
      this.message = 'This video has only one quality available.';
      this.cdr.detectChanges();
      this.clearMessageAfterTimeout();
    }
  }

  private clearMessageAfterTimeout() {
    setTimeout(() => {
      this.message = '';
      this.cdr.detectChanges();
    }, 5000); // timeout in ms
  }

  private setupQualityLevels() {
    if (this.hls && this.player) {
      this.qualityLevels = this.hls.levels.map((level, index) => ({
        label: `${level.height}p`,
        value: index
      }));

      this.qualityLevels.unshift({ label: 'Auto', value: -1 });

      const qualityOptions = {
        default: this.qualityLevels[0].value,
        options: this.qualityLevels.map(level => level.value),
        forced: true,
        onChange: (quality: number) => this.changeQuality(quality)
      };

      (this.player as any).quality = qualityOptions;

      const playerElements = this.player.elements as any;
      const qualityMenu = playerElements.settings?.quality?.querySelector('select');
      if (qualityMenu) {
        qualityMenu.innerHTML = this.qualityLevels
          .map(level => `<option value="${level.value}">${level.label}</option>`)
          .join('');
      }
    }
  }

  private addEventListeners(videoElement: HTMLVideoElement) {
    videoElement.addEventListener('click', () => {
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    });
  }

  private handleVideoEnd() {
    this.currentIndex++;
    if (this.currentIndex >= this.playlist.length) {
      this.currentIndex = 0;
    }
    this.media = this.playlist[this.currentIndex];
    this.initializePlayer();
  }

  private playVideo() {
    const videoElement = this.videoRef?.nativeElement;
    if (!videoElement) return;

    const playPromise = videoElement.play();
    if (playPromise !== undefined) {
      playPromise.catch(error => console.error('Error starting playback:', error));
    }
  }

  private cleanupPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }
    if (this.player) {
      this.player.destroy();
    }
  }
}