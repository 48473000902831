import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-podcast-episodes-uploads',
  templateUrl: './podcast-episodes-uploads.component.html',
  styleUrls: ['./podcast-episodes-uploads.component.scss']
})
export class PodcastEpisodesUploadsComponent {
  podcastEpisodesForm!: FormGroup;
  episodeUploadObservable!: Observable<any>;

  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message!: string;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private ContentService:ContentService) { }

  ngOnInit(): void {
    this.podcastEpisodesForm = this.formBuilder.group({
      podcastTitle: ['', [Validators.required, Validators.maxLength(50)]],
      episodeTitle: ['', [Validators.required, Validators.maxLength(50)]],
      episodeDesc:  ['', [Validators.required, Validators.maxLength(5000)]],
      episode: ['', [Validators.required, Validators.pattern('.+\\.(mp3|wav|ogg)$')]],
    });
  }

  putSignedUrl(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({

      'Content-Type': 'application/octet-stream',
    });
    return this.http.put(signedUrl, file, { headers });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if(this.podcastEpisodesForm.valid){
      this.showUploadAnimation = true;
      const formData = new FormData();
      formData.append('podcastTitle', this.podcastEpisodesForm.get('podcastTitle')?.value || '');
      formData.append('episodeTitle', this.podcastEpisodesForm.get('episodeTitle')?.value || '');
      formData.append('episodeDesc', this.podcastEpisodesForm.get('episodeDesc')?.value || '');

      const episodeFile = document.getElementById('episode') as HTMLInputElement;
      if (episodeFile.files && episodeFile.files.length > 0) {
          const file = episodeFile.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          await new Promise<void>((resolve) =>{
          reader.onloadend = () => {
            formData.append('episode', file.name);
            resolve();
          };
        });
      }

      this.ContentService.postPodcastEpisodeSignedUrl(formData).subscribe(
        (response) => {
          const episode_signed_url = response.episode_signed_url;
          console.log(episode_signed_url)
          if (episodeFile && episodeFile.files && episodeFile.files.length > 0) {
            this.episodeUploadObservable = this.putSignedUrl(episode_signed_url, episodeFile.files[0]);
          }

          this.episodeUploadObservable.subscribe(
            () => {
              this.showUploadAnimation = false;
              this.showUploadSuccessAnimation = true;
              // Call the API to save the data
              this.ContentService.postPodcastEpiosodes(formData).subscribe(
                (response) => {
                  console.log(response);
                  if(response.code === 201){
                    this.showUploadSuccessAnimation = false;
                    this.showSaveSuccessAnimation = true;
                    this.podcastEpisodesForm.reset();
                  }
                  else{
                    this.showUploadSuccessAnimation = false;
                    this.showSaveErrorAnimation = true
                    this.message = response.message;
                  }
                },
                (error) => {
                  this.showSaveErrorAnimation = true
                  this.message = error;
                }
              );
            },
            (error) => {
              this.showSaveErrorAnimation = true
              this.message = error;
            }
          );
        },
        (error) => {
          this.showSaveErrorAnimation = true
          this.message = error;
        }
      );

    }
    else{
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }

}
