import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { SigninComponent } from './signin/signin.component';
import { ContentComponent } from './content/content.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { PlayerComponent } from './player/player.component';
import { HomeComponent } from './home/home.component';
import { SplashComponent } from './splash/splash.component';
import { MovieDetailComponent } from './movie-detail/movie-detail.component';
import { PodcastDetailComponent } from './podcast-detail/podcast-detail.component';
import { ShowDetailComponent } from './show-detail/show-detail.component';
import { ThemesComponent } from './themes/themes.component';
import { AuthenticationService } from './services/authentication.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsNConditionsComponent } from './terms-n-conditions/terms-n-conditions.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { PaymentService } from './services/payment.service';
import { DashboardComponent } from './admin/views/dashboard/dashboard.component';
import { UploadsComponent } from './admin/views/uploads/uploads.component';
import { AdminComponent } from './admin/admin.component';
import { MoviesUploadsComponent } from './components/movies-uploads/movies-uploads.component';
import { MovieMetadataComponent } from './components/movie-metadata/movie-metadata.component';
import { PodcastDetailsUploadsComponent } from './components/podcast-details-uploads/podcast-details-uploads.component';
import { PodcastEpisodesUploadsComponent } from './components/podcast-episodes-uploads/podcast-episodes-uploads.component';
import { PodcastUploadsComponent } from './components/podcast-uploads/podcast-uploads.component';
import { PodcastDetailsMetadataComponent } from './components/podcast-details-metadata/podcast-details-metadata.component';
import { ShowDetailsUploadsComponent } from './components/show-details-uploads/show-details-uploads.component';
import { ShowEpisodesUploadsComponent } from './components/show-episodes-uploads/show-episodes-uploads.component';
import { ShowDetailsMetadataComponent } from './components/show-details-metadata/show-details-metadata.component';
import { ShowUploadsComponent } from './components/show-uploads/show-uploads.component';
import { SeasonDetailsUploadsComponent } from './components/season-details-uploads/season-details-uploads.component';
import { FeedDetailsUploadComponent } from './components/feed-details-upload/feed-details-upload.component';
import { FilterComponent } from './filter/filter.component';
import { ProfileComponent } from './profile/profile.component';
import { FamilyComponent } from './themes/family/family.component';
import { KidsComponent } from './themes/kids/kids.component';
import { LifestyleComponent } from './themes/lifestyle/lifestyle.component';
import { AnimationsComponent } from './themes/animations/animations.component';
import { SubscriptionDetailsComponent } from './components/subscription-details/subscription-details.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { GoogleCallbackComponent } from './components/google-callback/google-callback.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { PaymentPlansComponent } from './admin/views/payment-plans/payment-plans.component';
import { AdminContentComponent } from './admin/views/admin-content/admin-content.component';
import { AdminSignupComponent } from './admin/views/admin-signup/admin-signup.component';
import { AdminAuthGuard } from './admin/adminAuthGuard';
import { AuthGuard } from './guards/auth.guard';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';

const routes: Routes = [
  { path: '', component: SplashComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationService],
  },
  { path: 'signup', component: SignupComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'auth/google/callback', component: GoogleCallbackComponent },
  { path: 'content', component: ContentComponent, canActivate: [AuthenticationService]},
  { path: 'verify-email', component: EmailConfirmationComponent },
  { path: 'verify-otp', component: OtpVerificationComponent },
  { path: 'verify-email/:token', component: EmailVerificationComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'update-password', component: UpdatePasswordComponent },
  { path: 'player', component: PlayerComponent },
  { path: 'player/:id', component: PlayerComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthenticationService], },
  { path: 'player/:movieSlug/:id', component: PlayerComponent, canActivate: [PaymentService], },
  { path: 'player/:showSlug/:seasonSlug/:id', component: PlayerComponent, canActivate: [PaymentService], },
  { path: 'splash', component: SplashComponent },
  { path: 'movies/:id', component: MovieDetailComponent },
  { path: 'podcasts/:id', component: PodcastDetailComponent },
  { path: 'shows/:id', component: ShowDetailComponent },
  { path: 'themes/family', component: FamilyComponent, canActivate: [AuthGuard] },
  { path: 'themes/kids', component: KidsComponent, canActivate: [AuthGuard] },
  { path: 'themes/lifestyle', component: LifestyleComponent, canActivate: [AuthGuard] },
  { path: 'themes/animation', component: AnimationsComponent, canActivate: [AuthGuard] },
  { path: 'subscription-details/:id', component: SubscriptionDetailsComponent },
  { path: 'auth/callback', component: AuthCallbackComponent },
  { path: 'themes/:selectedTheme', component: ThemesComponent },
  { path: 'filter', component: FilterComponent },
  { path: 'admin-signup', component: AdminSignupComponent },
  { path: 'payment-error', component: PaymentErrorComponent },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AdminAuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'uploads',
        component: UploadsComponent,
        children: [
          {
            path: 'movies',
            component: MoviesUploadsComponent,
            children: [
              {
                path: 'metadata',
                component: MovieMetadataComponent,
              },
            ],
          },
          {
            path: 'podcasts',
            component: PodcastUploadsComponent,
            children: [
              {
                path: 'podcastdetails',
                component: PodcastDetailsUploadsComponent,
              },
              {
                path: 'podcastepisodes',
                component: PodcastEpisodesUploadsComponent,
              },
              {
                path: 'podcastmetadata',
                component: PodcastDetailsMetadataComponent,
              },
            ],
          },
          {
            path: 'shows',
            component: ShowUploadsComponent,
            children: [
              {
                path: 'showdetails',
                component: ShowDetailsUploadsComponent,
              },
              {
                path: 'seasons',
                component: SeasonDetailsUploadsComponent,
              },
              {
                path: 'showepisodes',
                component: ShowEpisodesUploadsComponent,
              },
              {
                path: 'showmetadata',
                component: ShowDetailsMetadataComponent,
              },
            ],
          },
          { path: 'RSSFeeds', component: FeedDetailsUploadComponent },
        ],
      },
      { path: 'payment-plans', component: PaymentPlansComponent },
      { path: 'admin-content', component: AdminContentComponent },
      { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
    ],
  },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms-n-conditions', component: TermsNConditionsComponent },
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}