<div class="reset-password">
    <app-light-logo class="logo"></app-light-logo>
    <form >     
        <mat-form-field appearance="outline">
            <mat-label>Please enter your email address</mat-label>
            <input matInput required name="email" id="email" [formControl] = "emailFormControl">
            <mat-error *ngIf="emailFormControl?.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </form>
    <mat-error class="network-error">{{networkErrorMessage}}</mat-error>
    <button mat-raised-button color="primary" [disabled]="emailFormControl.invalid" (click)="sendResetPasswordLink()">Reset</button>
</div>
<app-footer></app-footer>
    