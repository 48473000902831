import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { UserModel } from '../models/models';
import { LOCAL_STORAGE } from '../utils/constants';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) {}

  isLoading = false;

  async ngOnInit() {
    // Navigate to content if already authenticated
    const canAuthenticateResult = await this.auth.canActivate();
    if (canAuthenticateResult === true) {
      this.router.navigate(['/content']);
    }
  }

  signUpForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    username: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\+\d{1,3}\s?\d{1,13}$/),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  emailControl = this.signUpForm.get('email');
  usernameControl = this.signUpForm.get('username');
  phoneNumberControl = this.signUpForm.get('phoneNumber');
  passwordControl = this.signUpForm.get('password');

  isValidForm: boolean = false;

  networkErrorMessage: string = '';

  // User model for binding form values
  user: UserModel = new UserModel('', '', '', '');

  // Validate email
  getEmailErrorMessage() {
    if (
      this.emailControl?.hasError('required') ||
      this.emailControl?.hasError('email') ||
      this.emailControl?.hasError('pattern')
    )
      return 'Please enter a valid email';
    else return;
  }

  // Validate username
  getUsernameErrorMessage() {
    if (this.usernameControl?.hasError('required'))
      return 'Please enter a username';
    else return;
  }

  // Validate phoneNumber
  getPhoneNumberErrorMessage() {
    if (this.phoneNumberControl?.hasError('required')) {
      return 'Please enter a valid phone number';
    }

    if (this.phoneNumberControl?.hasError('pattern')) {
      return 'Phone number must be in the format +254 1234567890';
    }
    return 'Please enter a valid phone number';
  }

  // Validate password
  getPasswordErrorMessage() {
    if (this.passwordControl?.value.length < 8) {
      return 'Password must be at least 8 characters long';
    }

    if (this.passwordControl?.hasError('required')) {
      return 'Please enter a password';
    }
    return;
  }

  signUpWithEmailVerification() {
    if (this.signUpForm.valid) {
      this.isLoading = true;
      // Bind form values to the user model
      this.user.email = this.signUpForm.get('email')?.value;
      this.user.username = this.signUpForm.get('username')?.value;
      this.user.phoneNumber = this.signUpForm.get('phoneNumber')?.value;
      this.user.password = this.signUpForm.get('password')?.value;

      this.auth.signUp(
        this.user.email,
        this.user.username || '',
        this.user.phoneNumber || '',
        this.user.password
      ).subscribe({
        next: (response: any) => {
          console.log('Response:', response);

          if (response.success) {
            localStorage.setItem(LOCAL_STORAGE.emailForSignIn, this.user.email);
            // Navigate to verify email page
            this.router.navigate(['/verify-email']);
          } else {
            this.handleErrorResponse(response.error);
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.handleErrorResponse(error);
          this.isLoading = false;
        },
      });
    }
  }

  handleErrorResponse(error: any) {
    switch (error.error.message) {
      case 'User with this email already exists':
        this.networkErrorMessage = 'User with this email already exists';
        break;
      case 'User with this username already exists':
        this.networkErrorMessage = 'User with this username already exists';
        break;
      case 'User with this phone number already exists':
        this.networkErrorMessage = 'User with this phone number already exists';
        break;
      default:
        this.networkErrorMessage = 'Oops! Something went wrong.';
        console.error(error.statusCode);
        break;
    }
  }

  // Password visibility toggle
  hide = true;
}