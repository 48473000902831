import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, TimeoutError, forkJoin, of } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import {trigger,state,style,animate,transition} from '@angular/animations';
import { catchError, finalize, timeout } from 'rxjs/operators';
@Component({
  selector: 'app-movies-uploads',
  templateUrl: './movies-uploads.component.html',
  styleUrls: ['./movies-uploads.component.scss'],
  animations: [
    trigger('uploadAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('300ms')),
      transition(':leave', animate('300ms')),
    ]),
  ],
})
export class MoviesUploadsComponent {
  showMovieMetadataForm!: boolean;
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message!: string;
  submitted: boolean = false;


  showMovieMetadata(){
    this.showMovieMetadataForm = true;
  }
  movieForm!: FormGroup;
  imageUploadObservable!: Observable<any>;
  movieUploadObservable!: Observable<any>;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private ContentService:ContentService,
    private router: Router) { }

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/uploadMovieMetaData') {
          this.showMovieMetadataForm = false;
        }
      }
    });

    this.movieForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      movieDesc: ['', [Validators.required, Validators.maxLength(5000)]],
      image: ['', [Validators.required, Validators.pattern('.+\\.(jpg|jpeg|png)$')]],
      movie: ['', [Validators.required, Validators.pattern('.+\\.(mp4|avi|mov)$')]],
      contentTheme: ['Family', Validators.required]

    });
  }

  putSignedUrl(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({

      'Content-Type': 'application/octet-stream',
    });
    return this.http.put(signedUrl, file, { headers });
  }


  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.movieForm.valid) {
      this.showUploadAnimation = true;
      const formData = new FormData();
      formData.append('title', this.movieForm.get('title')?.value || '');
      formData.append('desc', this.movieForm.get('movieDesc')?.value || '');

      const imageFile = document.getElementById('image') as HTMLInputElement;
      if (imageFile.files && imageFile.files.length > 0) {
        const file = imageFile.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise<void>((resolve) => {
          reader.onloadend = () => {
            formData.append('image', file.name);
            resolve();
          };
        });
      }

      const movieFile = document.getElementById('movie') as HTMLInputElement;
      if (movieFile.files && movieFile.files.length > 0) {
        const file = movieFile.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise<void>((resolve) => {
          reader.onloadend = () => {
            formData.append('movie', file.name);
            resolve();
          };
        });
      }

      formData.append('contentTheme', this.movieForm.get('contentTheme')?.value || '');

      this.ContentService.postMovieSignedUrl(formData).subscribe(
        (response) => {
          const image_signed_url = response.image_signed_url;
          const movie_signed_url = response.movie_signed_url;
          console.log(image_signed_url);

          const imageUploadObservable = imageFile && imageFile.files && imageFile.files.length > 0 ?
            this.putSignedUrl(image_signed_url, imageFile.files[0]) :
            of(null);

          const movieUploadObservable = movieFile && movieFile.files && movieFile.files.length > 0 ?
            this.putSignedUrl(movie_signed_url, movieFile.files[0]) :
            of(null);

          forkJoin([imageUploadObservable, movieUploadObservable]).pipe(
            finalize(() => {
              this.showUploadAnimation = false;
              this.showUploadSuccessAnimation = true;
            }),
            catchError((error) => {
              console.log(error);
              this.showSaveErrorAnimation = true;
              console.log(error)
              this.message = error.message;
              return of(null);
            })
          ).subscribe(
            () => {
              // Call the API to save the data
              this.ContentService.postMovie(formData).pipe(timeout(60000)).subscribe(
                (response) => {
                  if(response.code === 201){
                    this.showUploadSuccessAnimation = false;
                    this.showSaveSuccessAnimation = true
                  }
                  else{
                    this.message = response.message;
                    this.showUploadSuccessAnimation = false;
                    this.showSaveErrorAnimation = true;
                  }
                },
                (error) => {
                  if (error instanceof TimeoutError) {
                    console.log(error)
                    this.showUploadSuccessAnimation = false;
                    this.showSaveSuccessAnimation = true;
                    this.movieForm.reset();
                  } else {
                    console.log(error);
                    this.showUploadSuccessAnimation = false;
                    this.showSaveErrorAnimation = true;
                    this.message = error;
                  }
                }
              );
            },
            (error) => {
              console.log(error);
              this.showSaveErrorAnimation = true;
              this.message = error
            }
          );
        },
        (error) => {
          console.log(error);
          this.showSaveErrorAnimation = true;
          this.message = error
        }
      );
    }
    else {
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }

}
