import { Component } from '@angular/core';
import { ContentService } from '../services/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { movies } from 'src/interfaces/content';
import { ToggleDescriptionService } from '../services/toggle-description.service';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-movies',
  templateUrl: './movies.component.html',
  styleUrls: ['./movies.component.scss']
})
export class MoviesComponent {
  id!:string;
  movie!: any;
  movieData!:{key:string, data:any}

  constructor(private contentService:ContentService,
    private route:ActivatedRoute,
    public toggleDesc: ToggleDescriptionService,
    private router: Router){}

  async ngOnInit(){
    this.getMovie();
  }

  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }

  playMedia() {

    const media: Media = {
      src: this.movie.converted_path,
      type: this.movie.mediaType || "application/x-mpegURL",
      thumbnail: this.movie.thumbnail || "../../assets/logo.png"
    }

    this.router.navigate(["/player"], { state: { media: media } })

  }

  getMovie(){
    this.id = this.route.snapshot.paramMap.get('id') ?? ""
    this.contentService.movieDetails(this.id).subscribe(
      response => {
        this.movie = response.data;
        this.movieData = {key: 'movies', data:this.movie}
      }
    );
  }
}
