<div class="form-container p-2" >
  <div class="top-title">
    <h4>Upload RSSFeed</h4>
  </div>
  <form class="form-content p-2" [formGroup]="rssFeedForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
      <div class="p-1 form-group">
          <input type="url" class="form-control" formControlName="url" placeholder="RSS Feed Url">
      </div>

      <button class="my-2" type="submit">Submit</button>
  </form>
</div>
