import { Component } from '@angular/core';

@Component({
  selector: 'app-option-buttons',
  templateUrl: './option-buttons.component.html',
  styleUrls: ['./option-buttons.component.scss']
})
export class OptionButtonsComponent {

}
