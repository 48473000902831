import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-show-details-uploads',
  templateUrl: './show-details-uploads.component.html',
  styleUrls: ['./show-details-uploads.component.scss']
})
export class ShowDetailsUploadsComponent {
  showDetailsForm!: FormGroup;
  imageUploadObservable!: Observable<any>;
  showUploadAnimation = false;
  showUploadSuccessAnimation = false;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message!: string;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private ContentService:ContentService) { }

  ngOnInit(): void {
    this.showDetailsForm = this.formBuilder.group({
      title:['', [Validators.required, Validators.maxLength(50)]],
      desc: ['', [Validators.required, Validators.maxLength(5000)]],
      image: ['',[Validators.required, Validators.pattern('.+\\.(jpg|jpeg|png)$')]],
      contentTheme: ['Family', Validators.required]
    });
  }

  putSignedUrl(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({

      'Content-Type': 'application/octet-stream',
    });
    return this.http.put(signedUrl, file, { headers });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if(this.showDetailsForm.valid){
      this.showUploadAnimation = true;
      const formData = new FormData();
      formData.append('title', this.showDetailsForm.get('title')?.value || '');
      formData.append('desc', this.showDetailsForm.get('desc')?.value || '');

      const imageFile = document.getElementById('image') as HTMLInputElement;
      if (imageFile.files && imageFile.files.length > 0) {
          const file = imageFile.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          await new Promise<void>((resolve) =>{
          reader.onloadend = () => {
            formData.append('image', file.name);
            resolve();
          };
        });
      }

      formData.append('contentTheme',this.showDetailsForm.get('contentTheme')?.value || '')

      this.ContentService.postShowDetailSignedUrl(formData).subscribe(
        (response) => {
          const image_signed_url = response.image_signed_url;
          console.log(image_signed_url)
          if (imageFile && imageFile.files && imageFile.files.length > 0) {
            this.imageUploadObservable = this.putSignedUrl(image_signed_url, imageFile.files[0]);
          }

          this.imageUploadObservable.subscribe(
            () => {
              this.showUploadAnimation = false;
              this.showSaveErrorAnimation = false;
              this.showUploadSuccessAnimation = true;
              // Call the API to save the data
              this.ContentService.postShowDetails(formData).subscribe(
                (response) => {
                  if(response.code === 201){
                    this.showUploadSuccessAnimation = false;
                    this.showSaveSuccessAnimation = true;
                  }
                  else{
                    this.showUploadSuccessAnimation = false;
                    this.showSaveErrorAnimation = true;
                    this.message = response.message;
                  }
                },
                (error) => {
                  this.showSaveErrorAnimation = true;
                  this.message = error;
                }
              );
            },
            (error) => {
              this.showSaveErrorAnimation = true;
              this.message = error;
            }
          );
        },
        (error) => {
          this.showSaveErrorAnimation = true;
          this.message = error;
        }
      );
    }
    else{
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }

}
