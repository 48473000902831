import { Injectable } from '@angular/core';
import { NetworkResponse } from '../utils/interfaces';
import { AuthenticationService } from './authentication.service';
import { LOCAL_STORAGE } from '../utils/constants';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private auth: AuthenticationService, private http: HttpClient, private router: Router) { }

  token = localStorage.getItem(LOCAL_STORAGE.accessToken)
  headers = new HttpHeaders({
    'Content-Type':  'application/json',
    "Authorization": `Bearer ${localStorage.getItem(LOCAL_STORAGE.accessToken)}`
  })
  userInfoEndpoint = `${environment.apiBaseUrl}/api/v1/user/info`
  usersEndpoint = `${environment.apiBaseUrl}/api/v1/users`
  paymentEndpoint = `${environment.apiBaseUrl}/api/v1/payment`


  async canActivate(): Promise<boolean | UrlTree> {
    let isAdmin = false;
    let isAuthenticated = await this.auth.canActivate()


    if (isAuthenticated === true) {
      

      await firstValueFrom(this.http.get(this.userInfoEndpoint,{headers: this.headers} )).
      then(async (result: any) => {
        if (result.code === 200) {
          //Check firebase auth
          if (result.data.is_staff && result.data.is_active) {
            isAdmin = true
          }
        }
      })
      .catch((error) => {
      console.error(error.error)
    })
    }

    // Redirect to the content page
    return isAdmin ? isAdmin : this.router.parseUrl('/content')
    
  }

  async getAllUsers(): Promise<NetworkResponse> {
    
   return await firstValueFrom(this.http.get(this.usersEndpoint, {headers: this.headers}))
      .then(async (response: any) => {
        if (response.code === 200) {
          const networkResponse: NetworkResponse = {
            Success: {
              code: response.code,
              message: response.data
            }

          } 
          return networkResponse
    
        }
        return  {
          Error : {
            code: response.code,
            message: response.message
          }
        }
      })
      .catch((error) => {
        console.error(error.error)
        return  {
          Error : {
            code: error.error.code,
            message: error.error.message
          }
        }
      })
    
    
  }

  async getAllUserPaymentInfoStatus(): Promise<NetworkResponse>{
    const parameters = new HttpParams().set('users', 'status');
   
    return await firstValueFrom(this.http.get(this.paymentEndpoint, {headers: this.headers, params: parameters}))
      .then(async (response: any) => {
        if (response.code === 200) {
          const networkResponse: NetworkResponse = {
            Success: {
              code: response.code,
              message: response.data
            }

          } 
          return networkResponse
    
        }
        return  {
          Error : {
            code: response.code,
            message: response.message
          }
        }
      })
      .catch((error) => {
        console.error(error.error)
        return  {
          Error : {
            code: error.error.code,
            message: error.error.message
          }
        }
      })
  }
}
