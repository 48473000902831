<div class="splash">
    <header class="header">
        <app-light-logo></app-light-logo>
        <nav class="navigation">
            <a routerLink="/signin">
                <app-inactive-btn class="btn--sign-in" [buttonAction]="signInBtnLabel"></app-inactive-btn>
            </a>
            <a routerLink="/signup">
                <app-active-btn class="btn--sign-up" [buttonAction]="signUpBtnLabel"></app-active-btn>
            </a>
        </nav>
    </header>
    <main class="content">
        <section class="welcome-message">
            <h1>Welcome to Wholesome Content</h1>
        </section>
        <section class="content--text">
            <h3>Free Viewing for the First Three Selected Titles</h3>
            <p>To enjoy more of our offering, sign up for a weekly, monthly, or even 24-hour access package.</p>
            <a routerLink="/signup">
                <app-active-btn [buttonClass]="signUpBtnClass" [buttonAction]="signUpBtnLabel"></app-active-btn>
            </a>
            <p id="cancel">Cancel anytime</p>
        </section>
        <section class="options">
            <app-splash-card *ngFor="let item of cardItems; index as i" [cardItem]="item"></app-splash-card>
        </section>
    </main>
</div>