import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';
import { LOCAL_STORAGE } from '../utils/constants';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    private router: Router
  ) {}

  token = localStorage.getItem(LOCAL_STORAGE.accessToken);
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE.accessToken)}`,
  });
  userInfoEndpoint = `${environment.apiBaseUrl}/api/users/profile`;
  usersEndpoint = `${environment.apiBaseUrl}/api/v1/users`;
  paymentEndpoint = `${environment.apiBaseUrl}/api/v1/payment`;
  paymentInfoEndpoint = `${environment.apiBaseUrl}/api/v1/payment/info`;
  cancelSubscriptionEndpoint = `${environment.apiBaseUrl}/api/v1/subscription/cancel`;

  async getUserInfo(): Promise<null | any> {
    let isAdmin = false;
    let userInfo = null;

    await firstValueFrom(
      this.http.get(this.userInfoEndpoint, { headers: this.headers })
    )
      .then(async (result: any) => {
        if (result.code === 200) {
          userInfo = result.data;
          //Check firebase auth
          if (result.data.is_staff && result.data.is_active) {
            isAdmin = true;
          }
        }
      })
      .catch((error) => {
        console.error(error.error);
      });
    return userInfo;
  }

  async getUserSubscriptionInfo(): Promise<null | any> {
    let paymentInfo = null;

    await firstValueFrom(
      this.http.get(this.paymentInfoEndpoint, {
        headers: this.headers,
        params: new HttpParams().set('latest', 'true'),
      })
    )
      .then(async (result: any) => {
        if (result.code === 200) {
          paymentInfo = result.data;
        }
      })
      .catch((error) => {
        console.error(error.error);
      });
    return paymentInfo;
  }

  async cancelSubscription(): Promise<boolean> {
    let isSubscriptionCancelled: boolean = false;

    await firstValueFrom(
      this.http.get(this.cancelSubscriptionEndpoint, { headers: this.headers })
    )
      .then(async (result: any) => {
        if (result.code === 200) {
          isSubscriptionCancelled = true;
        }
      })
      .catch((error) => {
        console.error(error.error);
      });
    return isSubscriptionCancelled;
  }
}
