import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { LOCAL_STORAGE } from '../utils/constants';
import { SplashCardItem } from '../utils/interfaces';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent {
  
  constructor( private router: Router, private auth: AuthenticationService) { }
  async ngOnInit() {
 
    //Navigate to content if already authenticated
    let canAuthenticateResult = await this.auth.canActivate()
    if (canAuthenticateResult === true) {
     this.router.navigate(["/content"])
   } 
  }
  signUpBtnLabel: string = 'Sign up';
  signInBtnLabel: string = 'Sign in';
  signUpBtnClass: string = 'btn--wide';

  cardItems: SplashCardItem[] = [
    {
      imgUri: '../../assets/fam.png',
      description: 'Family'
    },
    {
      imgUri: '../../assets/lifestyle.png',
      description: 'Lifestyle'
    },
    {
      imgUri: '../../assets/podcast.png',
      description: 'Podcasts'
    },
    {
      imgUri: '../../assets/kids.png',
      description: 'Kids'
    }
  ]

}
