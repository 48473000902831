<div class="privacy-container">
  <div class="text">
    <h1>NWPLUS TV PRIVACY POLICY</h1>
    <ol>
      <li class="title">Introduction</li>
      <ol type="a">
        <li>
          At Nwplus TV, we value your privacy. In this regard, we strive to
          ensure that the use of your personal data that we collect from you is
          lawful, protected, and stored in such a manner that conforms to data
          protection practices, resulting in user satisfaction.
        </li>
        <li>
          This privacy policy explains the information that we collect, its use
          and importance in the provision of Nwplus TV services.
        </li>
        <li>
          This privacy policy applies to all of our services unless specified
          otherwise.
        </li>
        <li>
          Please also read our Terms of Service which describe the terms under
          which our services are provided to you.
        </li>
        <li>
          Should you have any questions regarding our use of your personal data,
          please
          <a href="https://www.nwplus.biz/contact-us/">Contact Us</a> on our
          page.
        </li>
      </ol>
      <li class="title">Collection of Personal Data</li>
      <ol type="a">
        <li>
          <b>Overview:</b>
          We may receive and collect data from you in several ways including
          when you sign up to the Nwplus TV Service, stream, download or access
          Nwplus TV Service and content, you give payment details for
          subscription of Nwplus TV Service, you give ratings and preferences,
          account settings, you
          <a href="https://www.nwplus.biz/contact-us/">Contact Us</a>, you
          participate in a marketing or promotional activity conducted by
          ourselves, or provide data to us in any other way.
        </li>
        <li>
          <b>Information We Collect:</b> We collect the following categories of
          personal data when you sign in using our Nwplus TV Service:
          <ol type="i">
            <li>
              <b>Personal Information and Contact Information:</b> You will be
              required to provide your name, email address, and contact
              information when you sign up to our Service.
            </li>
            <li>
              <b>Financial Information:</b> In order to access our subscription
              packages, we shall collect your details of payment, including but
              not limited to certain credit and debit card information, as well
              as payment details for mobile money transactions and payments.
            </li>
            <li>
              <b>Preferences:</b> We collect and process certain information
              regarding your preference of movies, TV shows, podcasts and other
              data inferred from your choice and selection of content titles.
            </li>
            <li>
              <b>Search Queries and Voice Commands:</b> We collect information
              regarding your search queries and voice commands in order to
              display the content queried and suggest similar titles based on
              the search queries.
            </li>
            <li>
              <b>Location:</b> Based on your IP address when you use our Nwplus
              TV Service, we may collect information regarding your geographical
              information, based on the location of the device used to access
              our services.
            </li>
          </ol>
        </li>
        <li>
          <b>Purposes:</b> Collection and processing of your personal data is
          necessary for the creation of a contractual relationship between
          yourself and ourselves and for the enabling of access to the Nwplus TV
          Service. The purpose for which we process your data is therefore:
          <ol type="i">
            <li>
              To create and maintain your Nwplus TV account, and to authenticate
              your access to Nwplus TV Service;
            </li>
            <li>To provide you with user support services;</li>
            <li>
              To provide you with unique movies, shows and creative content that
              is tailored to your preferences using our robust Nwplus TV
              algorithm;
            </li>
            <li>
              To process your payment for your subscription to our Nwplus TV
              Service;
            </li>
            <li>
              To facilitate research, analytics, user experience improvement,
              technical support and security of the Nwplus TV Service;
            </li>
            <li>
              To establish the defence of legal claims, as well as investigate
              on alleged potential violations of our Terms of Service;
            </li>
            <li>
              To fulfil our obligations to our business partners for the purpose
              of offering Nwplus TV services and for the purposes of financial
              considerations thereto; and
            </li>
            <li>
              To fulfil the requirements under the laws of Kenya, more
              specifically, the Data Protection Act No. 24 of 2019.
            </li>
          </ol>
        </li>
      </ol>
      <li class="title">Disclosure of Personal Data</li>
      <ol type="a">
        <li>
          <b>Nifty Works Plus Limited:</b> We share your information with the
          Nifty Works Plus Limited family on a need-to-need basis for: providing
          you with access to our services; providing customer support; content
          development; service improvement and other connected purposes.
        </li>
        <li>
          <b>Service Providers and Partners:</b> We use other companies or
          contractors to perform services on our behalf or to assist us with the
          provision of services to you. Such service providers include:
          Marketing; IT Services; Banking Services for bank balances and
          processing of credit and debit card transactions; and to process and
          administer surveys.
        </li>
        <li>
          <b>Promotional Offers:</b> Nwplus TV may share your information with
          third parties to facilitate your participation in joint promotional
          offers. Where this happens, the third parties shall be responsible for
          their own privacy practices.
        </li>
        <li>
          <b>Protection of Nwplus Tv and Others:</b> Nwplus TV and its agents
          and service providers may disclose your information by dint of:
          <ol type="i">
            <li>
              Compliance with any applicable law, regulations and government
              request;
            </li>
            <li>
              Enforcing its Terms of Service, including investigating potential
              violations thereof;
            </li>
            <li>Prevention or cessation of suspected illegal conduct; and</li>
            <li>
              Protection of the rights of Nwplus TV, Nwplus TV users, and the
              general public.
            </li>
          </ol>
        </li>
        <li>
          <b>Business Transfers:</b> In the even of any merger, acquisition,
          sale, transfer of assets or other company restructuring, we shall
          transfer information, including personal information, provided that
          the receiving party expressly agrees to respect you handle your data
          in compliance with the Kenyan Data Protection Act, 2019 and consistent
          with our privacy policy.
        </li>
      </ol>
      <li class="title">Your Rights</li>
      You have the following rights with respect to the data processed by Nwplus
      TV:
      <ol type="a">
        <li><b>Access:</b> You have the right to access your personal data.</li>
        <li>
          <b>Amendment:</b> You have the right to the rectification of your
          personal data through your Nwplus TV account.
        </li>
        <li>
          <b>Deletion:</b> You have the right to delete and erase your personal
          data. We shall only retain the information for a defined period of
          time in accordance with clause X of this Privacy Policy; and data that
          is essential for financial and tax reporting purposes.
        </li>
        <li>
          <b>Objection to Marketing:</b> You have a right to the refusal and
          opting out of the use of your personal data in marketing
          notifications.
        </li>
        <li>
          <b>Objection to Other Processing:</b> You have the right to object to
          the processing of your data. We shall only continue with the
          processing of the data for valid reasons, and in accordance with this
          Privacy Policy.
        </li>
        <li>
          <b>Complaint:</b> You have a right to raise any complaint regarding
          the processing of your personal data, with the
          <a href="https://www.odpc.go.ke/"
            >Office of the Data Protection Commissioner.</a
          >
        </li>
      </ol>
      <li class="title">Changes to this Privacy Policy</li>
      <ol type="a">
        <li>
          We shall update this Privacy Policy from time to time, in compliance
          with the relevant and applicable laws, regulations and other
          compliance requirements.
        </li>
        <li>
          Relevant notice will be given regarding the change of policy and the
          date that the updated Privacy Policy takes effect.
        </li>
        <li>
          If you do not wish to accept the change of the Privacy Policy, you may
          cancel your use of the Nwplus TV Service.
        </li>
        <li>
          Use of Nwplus TV Service after a change of the Privacy Policy shall be
          deemed to be an acceptance to the change of the Privacy Policy.
        </li>
        <li>This Privacy Policy applies from 1 st May 2023.</li>
      </ol>
      <li class="title">Contact Us</li>
      If you have any questions regarding this Privacy Policy, please
      <a href="https://www.nwplus.biz/contact-us/">Contact Us.</a>
    </ol>
  </div>
</div>
