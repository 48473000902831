<div class="signin-page">
  <h1>Welcome back!</h1>
  <div class="signin-form">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="signInForm">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
              required
              name="email"
              [(ngModel)]="user.email"
              (keyup.enter)="signIn()"
              placeholder="example@gmail.com"
            />
            <mat-error *ngIf="signInForm.get('email')?.invalid">{{
              getErrorMessage()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              required
              [(ngModel)]="user.password"
              (keyup.enter)="signIn()"
              placeholder="Password"
            />
            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
            <mat-error *ngIf="signInForm.get('password')?.invalid">{{
              getPasswordErrorMessage()
            }}</mat-error>
          </mat-form-field>
          <mat-error class="network-error">{{ networkErrorMessage }}</mat-error>

          <button
            mat-raised-button
            type="button"
            color="primary"
            class="signin-btn"
            (click)="signIn()"
            [class.spinner]="isLoading"
            [disabled]="isLoading"
          >
            Sign in
          </button>
        </form>
        <p>Forgot password? <a routerLink="/reset-password">Reset</a></p>
        <p>Don't have an account? <a routerLink="/signup">Sign up</a></p>
      </mat-card-content>
    </mat-card>
  </div>
  <p>or</p>
  <div class="auth-buttons">
    <app-google-button></app-google-button>
  </div>
  <p>
    By signing in, you agree to the
    <a routerLink="/terms-n-conditions">Terms of Service</a> and
    <a routerLink="/privacy">Privacy Policy</a>
  </p>
</div>