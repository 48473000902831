import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-active-btn',
  templateUrl: './active-btn.component.html',
  styleUrls: ['./active-btn.component.scss']
})
export class ActiveBtnComponent {

  @Input() buttonAction: string = "nwplus";
  @Input() buttonClass?: string;

}
