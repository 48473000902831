import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { VideoModalComponent } from '../../components/video-modal/video-modal.component';
import { Media } from 'src/interfaces/player';
import { ContentItem } from 'src/interfaces/content';

@Component({
  selector: 'app-animations',
  templateUrl: './animations.component.html',
  styleUrls: ['./animations.component.scss']
})
export class AnimationsComponent implements OnInit {
  animationsContent: any[] = [];
  loading: boolean = true;

  constructor(
    private readonly contentService: ContentService,
    private readonly router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadAnimationsContent();
  }

  loadAnimationsContent() {
    this.contentService.getContentByTheme('Animations').pipe(
      tap(response => {
        const content = response.content;
        this.animationsContent = (content.movies ?? [])
          .concat(content.podcasts ?? [], content.shows ?? [])
          .sort((a: ContentItem, b: ContentItem) => {
            const dateA = new Date(a.datePublished ?? '');
            const dateB = new Date(b.datePublished ?? '');
            return dateA.getTime() - dateB.getTime();
          });
        this.loading = false;
      }),
      
      catchError(error => {
        this.animationsContent = [];
        this.loading = false;
        return throwError(() => error);
      })
    ).subscribe();
  }

  openVideoPlayer(content: any) {
    const media: Media = {
      src: content.movieUrl || content.podcastUrl || content.showUrl,
      title: content.title,
      description: content.description,
      type: content.type,
      // add other media properties if necessary
    };

    this.dialog.open(VideoModalComponent, {
      width: '80%',
      data: media
    });
  }

  // Method to navigate back
  navigateBack() {
    this.router.navigate(['/content']);
  }
}