<div class="admin-container">
    <!-- SIDEBAR -->
    <section id="sidebar" [ngClass]="status ? 'hide' : ''" class="dark">
        <a routerLink="/admin" class="brand">
            <app-light-logo></app-light-logo>
        </a>
        <ul class="side-menu top">
            <li>
                <a routerLink="/admin" routerLinkActive="active">
                    <i class='bx bxs-dashboard'></i>
                    <span class="text">Dashboard</span>
                </a>
            </li>
            <li>
                <a routerLink="/admin/uploads" routerLinkActive="active">
                    <i class='bx bx-upload'></i>
                    <span class="text">Uploads</span>
                </a>
            </li>

        </ul>
        <ul class="side-menu">
            <li>
                <a routerLink="/content" class="logout">
                    <i class='bx bxs-log-out-circle'></i>
                    <span class="text">Logout</span>
                </a>
            </li>
        </ul>
    </section>
    <!-- SIDEBAR -->

    <!-- CONTENT -->
    <section id="content" class="dark">
        <!-- NAVBAR -->
        <nav>
            <i (click)="addToggle()" class='bx bx-menu'></i>
            <a routerLink="/admin" class="profile">
                <img src="../../assets/logo.png">
            </a>
        </nav>
        <!-- NAVBAR -->

        <!-- MAIN -->
        <router-outlet></router-outlet>
        <!-- MAIN -->
    </section>
    <!-- CONTENT -->
</div>