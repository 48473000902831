import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ContentState } from "../state/content.state";

export const selectContentState = createFeatureSelector<ContentState>('content');

export const selectAllMovies = createSelector(
    selectContentState,
    (state: ContentState) => {
        return state.movies.slice().sort((a, b) => {
            const dateA = new Date(a.uploadDate ?? '1970-01-01');
            const dateB = new Date(b.uploadDate ?? '1970-01-01');
            return dateA.getTime() - dateB.getTime();
        });
    }
);

export const selectAllShows = createSelector(
    selectContentState,
    (state: ContentState) => state.shows
);

export const selectAllPodcasts = createSelector(
    selectContentState,
    (state: ContentState) => state.podcasts
);

export const selectContentLoading = createSelector(
    selectContentState,
    (state: ContentState) => state.loading
);

export const selectContentError = createSelector(
    selectContentState,
    (state: ContentState) => state.error
);

export const selectContent = createSelector(
    selectContentState,
    (state: ContentState) => {
        return state.selectedContent;
    }
);

export const selectContentId = createSelector(
    selectContentState,
    (state: ContentState) => {
        return state.selectedContentId;
    }
);

export const selectContentType = createSelector(
    selectContentState,
    (state: ContentState) => {
        return state.selectedContentType;
    }
);

export const selectContentLoaded = createSelector(
    selectContentState,
    (state: ContentState) => {
        return state.loaded;
    }
);