import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-podcast-details-metadata',
  templateUrl: './podcast-details-metadata.component.html',
  styleUrls: ['./podcast-details-metadata.component.scss']
})
export class PodcastDetailsMetadataComponent {
  podcastForm!: FormGroup;
  imageUploadObservable!: Observable<any>;

  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message : string = ' ';
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private ContentService:ContentService) { }

  ngOnInit(): void {
    this.podcastForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      author: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  putSignedUrl(signedUrl: string, file: File): Observable<any> {
    const headers = new HttpHeaders({

      'Content-Type': 'application/octet-stream',
    });
    return this.http.put(signedUrl, file, { headers });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if(this.podcastForm.valid){
      const formData = new FormData();
      formData.append('podcastTitle', this.podcastForm.get('title')?.value || '');
      formData.append('author', this.podcastForm.get('author')?.value || '');
      this.ContentService.postPodcastDetailMetaData(formData).subscribe(
        (response) => {
          if(response.code === 201 ){
            this.showSaveSuccessAnimation = true;
            this.podcastForm.reset();

          }
          else{
            this.showSaveErrorAnimation = true;
            this.message = response.message;
          }
        },
        (error) => {
          this.showSaveErrorAnimation = true;
          this.message = error;
        }
      );
    }
    else{
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }

}


