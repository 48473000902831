<div class="verify-email">
<app-light-logo class="logo"></app-light-logo>   
<div *ngIf="prevRoute === '/reset-password'" >

    <p>We have sent an email to <u>{{email}}</u> with a link to reset password.</p>
</div>

<div *ngIf="prevRoute === '/signup'" >
<p>We have sent an email to <u>{{email}}</u> with a link to verify your email address.</p>
<p><u id="click-here" (click)="resendLink()">Click here</u> if you did not receive an email</p>
</div>
<button mat-raised-button color="primary" (click)="onOkayButtonClick()">Okay</button>

</div>
<app-footer></app-footer>
