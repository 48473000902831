<div class=" upload-animation" *ngIf="showUploadAnimation">
  <button>Uploading...</button>
</div>
<div *ngIf="showUploadSuccessAnimation" class="upload-animation" >
  <button>Upload Successful</button>
</div>
<div *ngIf="showSaveSuccessAnimation" class="upload-animation" >
  <button>Data Was saved Successfully</button>
</div>
<div *ngIf="showSaveErrorAnimation" class="upload-animation">
  <button>{{this.message}}</button>
</div>
<div class="form-container p-2">
  <div class="top-title">
    <h4>Upload Podcast Details</h4>
  </div>
  <form class="form-content p-2" [formGroup]="podcastEpisodesForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
    <div class="p-1 form-group">
      <label>Podcast Title</label>
      <input type="text" class="form-control" formControlName="podcastTitle" placeholder="Podcast Title">
      <div *ngIf="podcastEpisodesForm.controls['podcastTitle'].invalid && (podcastEpisodesForm.controls['podcastTitle'].dirty || podcastEpisodesForm.controls['podcastTitle'].touched || submitted)">
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['podcastTitle'].errors?.['required']">Podcast Title is required.</div>
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['podcastTitle'].errors?.['maxLength']">Podcast Title exceeds the maximum lenth of 50</div>
      </div>
    </div>
    <div class="p-1 form-group">
      <label>Episode Title</label>
      <input type="text" class="form-control" formControlName="episodeTitle" placeholder="Episode Title">
      <div *ngIf="podcastEpisodesForm.controls['episodeTitle'].invalid && (podcastEpisodesForm.controls['episodeTitle'].dirty || podcastEpisodesForm.controls['episodeTitle'].touched || submitted)">
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeTitle'].errors?.['required']">Episode title is required.</div>
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeTitle'].errors?.['maxLength']">Episode Title exceeds the maximum lenth of 50</div>
      </div>
    </div>

    <div class="p-1 form-group">
      <label>Episode Description</label>
      <textarea class="form-control" formControlName="episodeDesc" placeholder="Episode Description"></textarea>
      <div *ngIf="podcastEpisodesForm.controls['episodeDesc'].invalid && (podcastEpisodesForm.controls['episodeDesc'].dirty || podcastEpisodesForm.controls['episodeDesc'].touched || submitted)">
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeDesc'].errors?.['required']">Description is required.</div>
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episodeDesc'].errors?.['maxLength']">Description exceeds the maximum lenth of 5000</div>
      </div>
    </div>

    <div class="p-1 form-group">
      <label>Episode</label>
      <input id="episode" type="file" class="form-control" formControlName="episode">
      <div *ngIf="podcastEpisodesForm.controls['episode'].invalid && (podcastEpisodesForm.controls['episode'].dirty || podcastEpisodesForm.controls['episode'].touched || submitted)">
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episode'].errors?.['required']">Episode is required.</div>
        <div class="formalerts" *ngIf="podcastEpisodesForm.controls['episode'].errors?.['pattern']">Wrong type of file, movie should have a valid extension(mp3, wav, ogg)</div>
      </div>

    </div>

    <button class="my-2" type="submit">Submit</button>
  </form>
</div>
