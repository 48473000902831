import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Media } from 'src/interfaces/player';

@Component({
  selector: 'app-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss']
})
export class PlayButtonComponent {
  @Input() contentData!: { key: string, data: any };

  constructor(private router: Router){
  }
  encodeUrl(url:string) : string{
    return url.replace(/ /g, "%20");
  }

  onPlayButton(contentData: any){
    console.log(contentData);

    if(contentData.key === 'movies'){
      const media: Media = {
        src: this.contentData.data.converted_path ? this.encodeUrl(this.contentData.data.converted_path) : this.contentData.data.movie,
        type: this.contentData.data.converted_path ? this.contentData.data.mediaType || 'application/x-mpegURL' : 'video/mp4',
        thumbnail: this.contentData.data.thumbnail || "../../assets/logo.png"
      }
      this.router.navigate(["/player"], { state: { media: media } })
      //this.router.navigate(['/player', this.encodeUrl(contentData.data.converted_path)]);
    }
    else if(contentData.key === 'podcasts'){
      this.router.navigate(['/podcast', contentData.data.slug, { queryParams: {autoplay: true} }]);
    }
    else if(contentData.key === 'shows'){
      const media: Media = {
        src:this.contentData.data.seasons[0].seasonEpisodes[0].converted_path? this.encodeUrl(contentData.data.seasons[0].seasonEpisodes[0].converted_path): this.contentData.data.seasons[0].seasonEpisodes[0].episode,
        type: this.contentData.data.seasons[0].seasonEpisodes[0].converted_path? this.contentData.data.mediaType || 'application/x-mpegURL' : 'video/mp4',
        thumbnail: this.contentData.data.thumbnail || "../../assets/logo.png"
      }
      this.router.navigate(["/player"], { state: { media: media } })
    }

  }

}
