import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-colored-logo',
  templateUrl: './colored-logo.component.html',
  styleUrls: ['./colored-logo.component.scss']
})
export class ColoredLogoComponent {
  constructor(private router: Router) { }
  
  logoClicked() {
    this.router.navigate(["/content"]);
  }
}
