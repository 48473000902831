import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { moviesResponse, podcastResponse, showResponse } from 'src/interfaces/content';
import { map, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ContentService {
  //private api_url:string          ='https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/content/'
  //private post_url:string         ='https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/postContent/'
  private api_url:string          ='https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/content/'
  private post_url:string         ='https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/postContent/'
  //private local_post_url:string   ='http://127.0.0.1:8000/api/v1/postContent/'
  private signed_url:string       ='https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/signedUrls/'
  //private local_signed_url:string   ='http://127.0.0.1:8000/api/v1/signedUrls/'
  private themes_url:string       ='https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/categories/'
  constructor(private httpClient:HttpClient){}

  movieList() {
  return this.httpClient.get<moviesResponse>(this.api_url + `movies/`)

  }

  movieDetails(id:string){
    return this.httpClient.get<moviesResponse>(this.api_url + `movies/${id}/`).pipe(map(movies => movies));
  }

  podcastList(){
    return this.httpClient.get<podcastResponse>(this.api_url + `podcasts/`).pipe(map(podcasts => podcasts));
  }

  podcast(id: string){
    return this.httpClient.get<podcastResponse>(this.api_url + `podcasts/${id}/`).pipe(map(podcast => podcast));
  }

  showList(){
    return this.httpClient.get<showResponse>(this.api_url + `shows/`).pipe(map(shows => shows));

  }
  show(id : string){
    return this.httpClient.get<showResponse>(this.api_url + `shows/${id}/`).pipe(map(show => show));
  }

  getContentByTheme(theme: string): Observable<any> {
    return this.httpClient.get(this.themes_url + `${theme}/`).pipe(map(contentTheme => contentTheme));
  }

  postMovie(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({
      //'Content-Type': 'application/json'
    });
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `movies/`, formData, options)

  }

  postMovieMetaData(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `movieMetaData/`, formData, options)
  }

  postPodcastDetails(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `podcastDetails/`, formData,options)
  }

  postPodcastDetailMetaData(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `podcastDetailMetaData/`, formData,options)
  }

  postPodcastEpiosodes(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `podcastEpisodes/`, formData, options)
  }

  postShowDetails(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `showDetails/`, formData, options)
  }

  postshowDetailMetaData(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `showDetailMetaData/`, formData, options)
  }

  postSeasonDetails(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `seasonDetails/`, formData,options)
  }

  postShowEpiosodes(formData:FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `showEpisodes/`, formData, options)
  }

  postRSSFeedUrl(formData:FormData):Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `RSSFeedUrl/`, formData, options)
  }

  postMovieSignedUrl(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `movieSignedUrl/`, formData, options)
  }

  postPodcastDetailSignedUrl(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `podcastDetailSignedUrl/`, formData, options)
  }

  postPodcastEpisodeSignedUrl(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `podcastEpisodeSignedUrl/`, formData, options)
  }

  postShowDetailSignedUrl(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `showDetailSignedUrl/`, formData)
  }

  postSeasonDetailSignedUrl(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `seasonDetailSignedUrl/`, formData, options)
  }

  postShowEpisodeSignedUrl(formData: FormData): Observable<any>{
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `showEpisodeSignedUrl/`, formData,options)
  }

  filter(query:string){
    return this.httpClient.get('https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/search/?query=' + query);
  }
}
