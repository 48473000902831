<div class=" upload-animation" *ngIf="showUploadAnimation">
  <button>Uploading...</button>
</div>
<div *ngIf="showUploadSuccessAnimation" class="upload-success-animation" >
  <button>Upload Successful</button>
</div>
<div *ngIf="showSaveSuccessAnimation" class="upload-animation" >
  <button>Data Was saved Successfully</button>
</div>
<div *ngIf="showSaveErrorAnimation" class="upload-animation">
  <button>{{this.message}}</button>
</div>
<div class="form-container p-2">
  <div class="top-title">
    <h4>Upload Season Details</h4>
  </div>
  <form class="form-content p-2" [formGroup]="seasonDetailsForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
      <div class="p-1 form-group">
        <label>Show Title</label>
        <input type="text" class="form-control" formControlName="showTitle" placeholder="Show Title">
        <div *ngIf="seasonDetailsForm.controls['showTitle'].invalid && (seasonDetailsForm.controls['showTitle'].dirty || seasonDetailsForm.controls['showTitle'].touched || submitted)">
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['showTitle'].errors?.['required']">Title is required.</div>
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['showTitle'].errors?.['maxLength']">Title exceeds the maximum lenth of 50</div>
        </div>

      </div>
      <div class="p-1 form-group">
        <label>Season Number</label>
        <input type="number" class="form-control" formControlName="seasonNo" placeholder="Season No">
        <div *ngIf="seasonDetailsForm.controls['seasonNo'].invalid && (seasonDetailsForm.controls['seasonNo'].dirty || seasonDetailsForm.controls['seasonNo'].touched || submitted)">
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['seasonNo'].errors?.['required']">Season Number is required.</div>
        </div>
      </div>

      <div class="p-1 form-group">
        <label>Season Description</label>
        <textarea class="form-control" formControlName="desc" placeholder="Season Description"></textarea>
        <div *ngIf="seasonDetailsForm.controls['desc'].invalid && (seasonDetailsForm.controls['desc'].dirty || seasonDetailsForm.controls['desc'].touched || submitted)">
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['desc'].errors?.['required']">Description is required.</div>
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['desc'].errors?.['maxLength']">Description exceeds the maximum lenth of 5000</div>
        </div>
      </div>

      <div class="p-1 form-group">
        <label>Image</label>
        <input id="image" type="file" class="form-control" formControlName="image" placeholder="Image">
        <div *ngIf="seasonDetailsForm.controls['image'].invalid && (seasonDetailsForm.controls['image'].dirty || seasonDetailsForm.controls['image'].touched || submitted)">
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['image'].errors?.['required']">Title is required.</div>
          <div class="formalerts" *ngIf="seasonDetailsForm.controls['image'].errors?.['pattern']">Wrong type of file, image should have a valid extension(jpg,jpeg, png)</div>
        </div>
      </div>

      <button class="my-2" type="submit">Submit</button>
  </form>
</div>
