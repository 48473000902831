import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-movie-metadata',
  templateUrl: './movie-metadata.component.html',
  styleUrls: ['./movie-metadata.component.scss']
})
export class MovieMetadataComponent {
  metaDataForm!: FormGroup;
  showSaveSuccessAnimation = false;
  showSaveErrorAnimation = false;
  message : string = ' ';
  submitted = false;

  constructor(private formBuilder:FormBuilder,private http: HttpClient,
    private ContentService: ContentService){}

  ngOnInit(): void{
    this.metaDataForm = this.formBuilder.group({
      movieTitle : ['', [Validators.required, Validators.maxLength(50)]],
      director: ['', [Validators.required, Validators.maxLength(50)]],
      year: ['', Validators.required]

    })

  }

  async onSubmit(): Promise<void>{
    this.submitted = true;
    if(this.metaDataForm.valid){
      const formData = new FormData

      formData.append('movieTitle', this.metaDataForm.get('movieTitle')?.value || '')
      formData.append('director', this.metaDataForm.get('director')?.value || '')
      formData.append('year', this.metaDataForm.get('year')?.value || '')

      this.ContentService.postMovieMetaData(formData).subscribe(
        response =>{
          if(response.code === 201){
            this.showSaveSuccessAnimation = true;
          }
          else{
            this.showSaveErrorAnimation = true;
            this.message = response.message;
          }
        },
        (error) => {
          this.showSaveErrorAnimation =  true;
          this.message = error
        }

      );
    }
    else{
      this.showSaveErrorAnimation = true;
      this.message = 'Form is invalid. Please check the required fields.';
    }
  }

}
