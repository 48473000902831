import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse
} from '@angular/common/http';
import {
  MovieResponse,
  PodcastResponse,
  ShowResponse,
  AllContentResponse,
  Filter
} from 'src/interfaces/content';
import { of, map, Observable, throwError, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private readonly api_url: string = `${environment.apiBaseUrl}/api/content/`;
  // private api_url: string = 'http://localhost:5000/api/content/';
  private readonly season_url: string = `${environment.apiBaseUrl}/api/seasons/`;
  // private season_url: string = 'http://localhost:5000/api/seasons/';
  private readonly episode_url: string = `${environment.apiBaseUrl}/api/contents/`;
  // private episode_url: string = 'http://localhost:5000/api/contents/';
  private readonly post_url: string = 'http://localhost:5000/api/content/';
  private readonly delete_url: string = 'http://localhost:5000/api/content/';
  private readonly gcp_delete_url: string = 'http://localhost:5000/api/content/gcp/';
  private readonly signed_url: string = 'https://api-dot-nwplustv-bbc5a.uc.r.appspot.com/api/v1/signedUrls/';
  
  constructor(private readonly httpClient: HttpClient) { }

  private getAuthHeaders(): HttpHeaders {
    const accessToken = sessionStorage.getItem('2lietkapas');
    let headers = new HttpHeaders();
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }

  // Handling errors
  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('Service Error:', error); // Log the error in the service
    return throwError(() => error); // Rethrow it back to the component
  }

  // Generalized method to fetch content by type
  getContentByType(contentType: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.api_url}type/${contentType}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Fetch content by theme
  getContentByTheme(theme: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.api_url}theme/${theme}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Fetch content by ID - generalized method
  getContentById(contentType: string, id: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.api_url}${contentType}/${id}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }
  
  getAllContent(): Observable<AllContentResponse> {
    const movies$ = this.getContentByType('movies').pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of({ data: [] });
        } else {
          return throwError(() => new Error(error.message));
        }
      })) as Observable<MovieResponse>;
    const podcasts$ = this.getContentByType('podcasts').pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of({ data: [] });
        } else {
          return throwError(() => new Error(error.message));
        }
      })) as Observable<PodcastResponse>;
    const shows$ = this.getContentByType('shows').pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of({ data: [] });
        } else {
          return throwError(() => new Error(error.message));
        }
      })) as Observable<ShowResponse>;

    return forkJoin({
      movies: movies$,
      podcasts: podcasts$,
      shows: shows$
    }).pipe(
      map(({ movies, podcasts, shows }) => {
        return {
          movies,
          podcasts,
          shows
        };
      }),
      catchError(error => {
        return throwError(() => new Error(error.message));
      })
    );
  }

  // Generalized upload method - assuming formData includes both file and metadata
  uploadContent(contentType: string, formData: FormData): Observable<any> {
    const headers = this.getAuthHeaders();
    const options = { headers };
    return this.httpClient.post(`${this.api_url}${contentType}`, formData, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateContentUrls(contentType: string, contentId: string, publicUrl: string, imageUrl: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.put(`${this.api_url}${contentType}/${contentId}/urls`, { publicUrl, imageUrl }, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Generalized update method - assuming formData includes updated data and possibly a file
  updateContent(contentType: string, id: string, formData: FormData): Observable<any> {
    const headers = this.getAuthHeaders();
    const options = { headers };
    return this.httpClient.put(`${this.api_url}${contentType}/${id}`, formData, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Delete content by type and ID
  deleteContent(contentType: string, id: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.delete(`${this.api_url}${contentType}/${id}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Delete content from GCP by type and ID
  deleteFromGCP(contentType: string, id: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.delete(`${this.api_url}${contentType}/${id}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Season methods
  createSeason(showId: string, formData: FormData): Observable<any> {
    const headers = this.getAuthHeaders();
    const options = { headers };
    return this.httpClient.post(`${this.season_url}${showId}`, formData, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSeason(seasonId: string, formData: FormData): Observable<any> {
    const headers = this.getAuthHeaders();
    const options = { headers };
    return this.httpClient.put(`${this.season_url}${seasonId}`, formData, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSeason(seasonId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.season_url}${seasonId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllSeasons(showId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.get(`${this.season_url}show/${showId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteSeason(seasonId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.httpClient.delete(`${this.season_url}${seasonId}`, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  // Episode methods
  uploadEpisode(contentType: string, id: string, seasonId: string | null, formData: FormData): Observable<any> {
    const headers = this.getAuthHeaders();
    const options = { headers };
    const url = contentType === 'podcasts' 
      ? `${this.episode_url}${contentType}/${id}` 
      : `${this.episode_url}${contentType}/${id}/${seasonId}`;
    return this.httpClient.post(url, formData, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEpisodeUrls(contentType: string, episodeId: string, publicUrl: string, imageUrl: string): Observable<any> {
    console.log('updateEpisodeUrls', contentType, episodeId, publicUrl, imageUrl);
    const headers = this.getAuthHeaders();
    return this.httpClient.put(`${this.episode_url}${contentType}/${episodeId}/update-urls`, { publicUrl, imageUrl }, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateEpisode(contentType: string, id: string, seasonId: string | null, episodeId: string, formData: FormData): Observable<any> {
    const headers = this.getAuthHeaders();
    const options = { headers };
    let url: string;

    if (contentType === 'podcasts') {
      url = `${this.episode_url}${contentType}/${id}/${episodeId}`;
    } else if (contentType === 'shows') {
      if (seasonId) {
        url = `${this.episode_url}${contentType}/${id}/${seasonId}/${episodeId}`;
      } else {
        // Handle the case where seasonId is null or empty
        url = `${this.episode_url}${contentType}/${id}/${episodeId}`;
      }
    } else {
      throw new Error('Invalid content type');
    }

    return this.httpClient.put(url, formData, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllEpisodes(contentType: string, contentId: string, seasonId: string | null): Observable<any> {
    const headers = this.getAuthHeaders();
    const url = contentType === 'podcasts' 
      ? `${this.episode_url}${contentType}/${contentId}/episodes` 
      : `${this.episode_url}${contentType}/${contentId}/${seasonId}/episodes`;
    return this.httpClient.get(url, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  getEpisodeById(contentType: string, seasonId: string | null, episodeId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    const url = contentType === 'podcasts' 
      ? `${this.episode_url}${contentType}/episodes/${episodeId}` 
      : `${this.episode_url}${contentType}/${seasonId}/episodes/${episodeId}`;
    return this.httpClient.get(url, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteEpisode(contentType: string, seasonId: string | null, episodeId: string): Observable<any> {
    const headers = this.getAuthHeaders();
    const url = contentType === 'podcasts' 
      ? `${this.episode_url}${contentType}/episodes/${episodeId}` 
      : `${this.episode_url}${contentType}/${seasonId}/${episodeId}`;
    return this.httpClient.delete(url, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  filter(contentType: string, filter: Partial<Filter>): Observable<any> {
    const headers = this.getAuthHeaders();
    
    // Convert filter object to query parameters
    let params = new HttpParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key]) {
        params = params.set(key, filter[key] as string);
      }
    }

    const requestUrl = `${this.api_url}filter/${contentType}`;

    return this.httpClient.post(requestUrl, filter, { headers, params })
      .pipe(
        catchError(this.handleError)
      );
  }
  
  movieList(): Observable<MovieResponse> {
    return this.getContentByType('movies');
  }

  movieDetails(id: string): Observable<MovieResponse> {
    return this.getContentById('movies', id).pipe(map(movies => movies));
  }

  podcastList(): Observable<PodcastResponse> {
    return this.getContentByType('podcasts');
  }

  podcast(id: string): Observable<PodcastResponse> {
    return this.getContentById('podcasts', id).pipe(map(podcast => podcast));
  }

  showList(): Observable<ShowResponse> {
    return this.getContentByType('shows');
  }
  
  show(id: string): Observable<ShowResponse> {
    return this.getContentById('shows', id).pipe(map(show => show));
  }
  
  postMovie(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({
      //'Content-Type': 'application/json'
    });
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `movies/`, formData, options);
  }

  postMovieMetaData(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `movieMetaData/`, formData, options);
  }

  postPodcastDetails(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `podcastDetails/`, formData, options);
  }

  postPodcastDetailMetaData(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `podcastDetailMetaData/`, formData, options);
  }

  postPodcastEpisodes(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `podcastEpisodes/`, formData, options);
  }

  postShowDetails(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `showDetails/`, formData, options);
  }

  postshowDetailMetaData(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `showDetailMetaData/`, formData, options);
  }

  postSeasonDetails(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `seasonDetails/`, formData, options);
  }

  postShowEpisodes(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `showEpisodes/`, formData, options);
  }

  postRSSFeedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.post_url + `RSSFeedUrl/`, formData, options);
  }

  postMovieSignedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `movieSignedUrl/`, formData, options);
  }

  postPodcastDetailSignedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `podcastDetailSignedUrl/`, formData, options);
  }

  postPodcastEpisodeSignedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `podcastEpisodeSignedUrl/`, formData, options);
  }

  postShowDetailSignedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `showDetailSignedUrl/`, formData);
  }

  postSeasonDetailSignedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `seasonDetailSignedUrl/`, formData, options);
  }

  postShowEpisodeSignedUrl(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const options = {
      headers: headers,
      withCredentials: true
    };
    return this.httpClient.post(this.signed_url + `showEpisodeSignedUrl/`, formData, options);
  }
}
