<div class="signup-page">
  <h1>Create your account</h1>
  <div class="signup-form">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="signUpForm">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
              name="email"
              [(ngModel)]="user.email"
              required
              placeholder="example@gmail.com"
            />
            <mat-error *ngIf="signUpForm.get('email')?.invalid">{{
              getEmailErrorMessage()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input
              matInput
              formControlName="username"
              name="username"
              [(ngModel)]="user.username"
              required
              placeholder="johndoe"
            />
            <mat-error *ngIf="signUpForm.get('username')?.invalid">{{
              getUsernameErrorMessage()
            }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              required
              name="password"
              [(ngModel)]="user.password"
              placeholder="Password"
            />
            <button
              mat-icon-button
              matSuffix
              type="button"
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
            <mat-error
              class="password-error"
              *ngIf="signUpForm.get('password')?.invalid"
              >{{ getPasswordErrorMessage() }}</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              formControlName="phoneNumber"
              name="phoneNumber"
              [(ngModel)]="user.phoneNumber"
              required
              placeholder="+254 123456789"
            />
            <mat-error *ngIf="signUpForm.get('phoneNumber')?.invalid">{{
              getPhoneNumberErrorMessage()
            }}</mat-error>
          </mat-form-field>
          <mat-error class="network-error">{{ networkErrorMessage }}</mat-error>
          <button
            mat-raised-button
            class="signup-btn"
            type="submit"
            color="primary"
            (click)="signUpWithEmailVerification()"
            [class.spinner]="isLoading"
            [disabled]="isLoading"
          >
            Sign up
          </button>
        </form>

        <p>Already have an account? <a routerLink="/signin">Sign in</a></p>
      </mat-card-content>
    </mat-card>
  </div>
  <p>or</p>
  <div class="auth-buttons">
    <app-google-button></app-google-button>
  </div>
  <p>
    By signing up, you agree to the
    <a routerLink="/terms-n-conditions">Terms of Service</a> and
    <a routerLink="/privacy">Privacy Policy</a>
  </p>
</div>