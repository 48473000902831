import { Component } from '@angular/core';

@Component({
  selector: 'app-view-plans-button',
  templateUrl: './view-plans-button.component.html',
  styleUrls: ['./view-plans-button.component.scss']
})
export class ViewPlansButtonComponent {

}
