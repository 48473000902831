<div class="video-container">
  <!--<img src="../../../assets/logo.png" alt="logo" (click)="logoClicked()">
	<app-colored-logo></app-colored-logo>-->
  <video
    [ngClass]="videoSize"
    #videoRef
    controls
    crossorigin
    playsinline
    poster="{{ media.thumbnail }}"
    style="--plyr-color-main: #995bf7"
  ></video>
</div>
