import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import * as Plyr from 'plyr';
import Hls from 'hls.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { Media } from 'src/interfaces/player';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  constructor(private elementRef: ElementRef, private router: Router) {}
  videoSize!: string;
  logoClicked() {
    this.router.navigate(['/content']);
  }

  @ViewChild('videoRef', { static: true }) videoReference?: ElementRef;

  @Input() media!: Media;

  hlsPlayer?: Hls;

  // Instantiate a Video.js player OnInit
  ngOnInit() {
    function addSourceToVideo(
      element: { appendChild: (arg0: HTMLSourceElement) => void },
      src: string,
      type: string
    ) {
      var source = document.createElement('source');
      source.src = src;
      source.type = type;
      element.appendChild(source);
    }

    this.hlsPlayer = new Hls();

    if (this.media.type === 'video/mp4') {
      this.videoSize = 'videoStyle';
      addSourceToVideo(
        this.videoReference?.nativeElement,
        this.media.src,
        'video/mp4'
      );
      //this.videoReference?.nativeElement.play();
    } else {
      this.hlsPlayer.loadSource(this.media.src);
      this.hlsPlayer.attachMedia(this.videoReference?.nativeElement);
    }
    this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      //get available qualities
      const availableQualities = this.hlsPlayer!!.levels.map(
        (level) => level.height
      );
      //player config options
      const playerOptions: Plyr.Options = {
        autoplay: Boolean(this.media.autoplay),
        //this.media.autoplay:true,
        speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4] },
        storage: { enabled: true, key: 'plyr' },
        //ratio: '16:9',
        ratio: Boolean(this.media.ratio) ? this.media.ratio : '16:9',
        tooltips: { controls: true, seek: true },
        captions: { active: true, language: 'auto', update: false },
        quality: {
          default: availableQualities[0],
          options: availableQualities,
          forced: true,
          onChange: (e: any) => updateQuality(e),
        },
      };

      new Plyr(this.videoReference?.nativeElement, playerOptions);
    });

    const updateQuality = (newQuality: number) => {
      this.hlsPlayer?.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          this.hlsPlayer!.currentLevel = levelIndex;
        }
      });
    };
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.hlsPlayer) {
      this.hlsPlayer.destroy();
    }
  }
}
