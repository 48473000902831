import { Component } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { NetworkResponse } from 'src/app/utils/interfaces';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  constructor(private admin: AdminService) {}

  users = [];
  paymentStatus = [];
  activeSubscribers = [];
  inactiveSubscribers = [];

  async ngOnInit() {
    const usersResponse: NetworkResponse = await this.admin.getAllUsers();
    const paymentStatusResponse: NetworkResponse =
      await this.admin.getAllUserPaymentInfoStatus();

    if (usersResponse.Success) {
      this.users = usersResponse.Success.message;
    } else {
    }

    if (paymentStatusResponse.Success) {
      this.activeSubscribers = paymentStatusResponse.Success.message.active;
      this.inactiveSubscribers = paymentStatusResponse.Success.message.inactive;
    } else {
    }
  }
}
