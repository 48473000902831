import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { UserModel } from '../models/models';
import { getSearchParameterByName } from '../utils/httpHandlers';
import { LOCAL_STORAGE, USER_MNGMT_ACTIONS } from '../utils/constants';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent {
  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) {
    // Check for action code in query string
    if (getSearchParameterByName('oobCode')) {
      const mode = getSearchParameterByName('mode');
      const actionCode = getSearchParameterByName('oobCode');

      if (mode === USER_MNGMT_ACTIONS.verifyEmail) {
        this.auth.handleUserManagementAction(mode, actionCode);
      }

      if (mode === USER_MNGMT_ACTIONS.resetPassword) {
        const userMngmt = JSON.stringify({
          mode,
          actionCode,
        });

        localStorage.setItem(LOCAL_STORAGE.userMngmtActionCode, userMngmt);
        this.router.navigate(['/update-password']);
      }
    }
  }

  isLoading: Boolean = false;

  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  checkAuthState = async () => {
    let canAuthenticateResult = await this.auth.canActivate();

    if (canAuthenticateResult === true) {
      this.router.navigate(['/content']);
    }
  };

  async ngOnInit() {
    this.checkAuthState();
  }

  user = new UserModel('', '');
  emailControl = this.signInForm.get('email');
  passwordControl = this.signInForm.get('password');
  networkErrorMessage: string = '';

  // Validate email
  getErrorMessage() {
    if (
      this.emailControl?.hasError('required') ||
      this.emailControl?.hasError('email') ||
      this.emailControl?.hasError('pattern')
    ) {
      return 'Please enter a valid email';
    } else {
      return '';
    }
  }

  getPasswordErrorMessage() {
    if (this.passwordControl?.hasError('required')) {
      return 'Please enter a password';
    }

    if (this.passwordControl?.value.length < 8) {
      return 'Password too short';
    }
    return '';
  }

  signIn() {
    if (this.signInForm.valid) {
      this.isLoading = true;

      const observer = {
        next: (response: any) => {
          if (response.success) {
            if (response.data.user.emailVerified) {
              this.router.navigate(['/content']);
            } else {
              this.networkErrorMessage = 'Please verify your email';
            }
          } else {
            this.handleErrorResponse(response.error);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.handleErrorResponse(error);
          this.isLoading = false;
        }
      };

      this.auth.signIn(
        this.signInForm.value.email,
        this.signInForm.value.password
      ).subscribe(observer);
    }
  }

  handleErrorResponse(error: any) {
    switch (error.error.statusCode) {
      case 404:
        this.networkErrorMessage = 'Account not found.';
        break;
      case 403:
        this.networkErrorMessage = 'Please verify your email';
        break;
      case 401:
        this.networkErrorMessage = 'Incorrect password';
        break;
      case 400:
        this.networkErrorMessage = 'Invalid request. Please try again.';
        break;
      default:
        this.networkErrorMessage = 'Oops! Something went wrong.';
        console.error(error.statusCode);
        break;
    }
  }

  // Password visibility toggle
  hide = true;
}