<div class="image-container" *ngIf="contentData" >
  <img class="img-fluid"  [src]="contentData.data.image"  alt=""/>
  <app-header></app-header>
  <div class="overlay py-5">
    <h1>{{contentData.data.title}}</h1>
    <div class="desc py-2 px-3">{{contentData.data.desc||contentData.data.description}}</div>
  </div>
  <div class="button-overlay px-3 d-flex ">
    </div>
    <div class="col-4">
      <button type="button" class="btn btn-sm" (click)="onInfoButtonClick(contentData)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.75 5.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-1.5 0V5.5zM8 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </svg>
      </button>
    </div>
    <div class="col-4">
      <button type="button" class="btn btn-sm" >
        View Plans
      </button>
    </div>
  </div>


