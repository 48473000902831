import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-light-logo',
  templateUrl: './light-logo.component.html',
  styleUrls: ['./light-logo.component.scss']
})
export class LightLogoComponent {
  constructor(private router: Router) {
    
  }
  
  logoClicked() {
    this.router.navigate(["/content"]);
  }
}
