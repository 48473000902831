<div class="terms-container">
  <div class="text">
    <h1>NWPLUS TV TERMS OF SERVICE</h1>
    <h2>Welcome to Nwplus TV!</h2>
    <p>Please read the following Terms of Service carefully.</p>
    <ol>
      <li>About Our Services</li>
      <li>Acceptable Use of Our Services</li>
      <li>Licences</li>
      <li>Content Services</li>
      <li>
        Reporting Third-Party Copyright, Trademark and Other Intellectual
        Property Infringement
      </li>
      <li>Limitation of Liability</li>
      <li>Indemnification</li>
      <li>Dispute Resolution</li>
      <li>Termination of Our Services</li>
      <li>Miscellaneous Provisions</li>
    </ol>

    <ol>
      <li id="about" class="title">About Our Services</li>
      <ol type="a">
        <li>
          Nifty Works Plus Limited (“Nwplus TV”) is an award-winning creative
          company that produces high-definition creative content. As such, its
          users can stream or temporarily download shows, movies, podcasts or
          audio-visual material (“Content”) by using an electronic device that
          is capable of connecting to the internet, including computers,
          laptops, game consoles, mobile phones, tablets, and TV&apos;s.
        </li>
        <li>
          The Nwplus TV Service is made available to you by Nifty Works Plus
          Limited on the terms and conditions stated herein and contained on the
          website
          <a href="https://www.nwplus.biz">www.nwplus.biz</a> or
          <a href="https://nwplus.tv">nwplus.tv</a>. Please note that the
          Privacy Policy is incorporated into the Nwplus TV Terms of Service.
        </li>
        <li>
          <b>Privacy and Security Principles:</b> Since the founding of Nifty
          Works Plus, we have built our services with strong privacy and
          security principles in mind.
        </li>
        <li>
          <b>Content-Creation:</b> We are an award-winning creative company that
          has recognized the demand for digital content in Africa and have
          yielded to that demand by producing content for the digitally-savvy
          African.
        </li>
        <li>
          <b>Safety, Security and Integrity:</b> We ensure that we are
          constantly working to secure our services. This includes dealing with
          cases of violation of our terms of service as well as cyber bullying.
          Gross violation of our terms of service will result in action being
          taken, including but not limited to Termination of Our Services as
          elaborated below.
        </li>
      </ol>

      <li id="acceptable-use" class="title">Acceptable Use of Our Services</li>
      <ol type="a">
        <li>
          <b>Our Terms and Policies:</b>
          You must use our Services according to our Terms of Service, which are
          binding in nature. If you violate our Terms or policies, we may take
          action with respect to your account, including disabling or suspending
          your subscription, and, if we do, you agree not to create another
          account without our express consent. Disabling or suspending your
          subscription will be in accordance with the
          <a routerLink="">Termination of Our Services</a>
          section below.
        </li>
        <li>
          <b>Legal and Acceptable Use:</b> You must use our Services for legal,
          authorized and acceptable purposes. You will not use (or assist others
          in using) our Services in ways that violate or infringe on the rights
          of Nwplus TV, our users or others, including infringement of
          intellectual property and other proprietary rights. You may only use
          Nwplus TV if you comply with the following:
          <ol type="i">
            <li>
              You are subscribed as a Nwplus TV member either on the Nwplus TV
              free plan or Nwplus TV subscription plan (per episode, weekly or
              monthly)
            </li>
            <li>
              You are allowed by the subscriber to use the Nwplus TV Service
              through the subscriber&apos;s account.
            </li>
            <li>
              You must be 18 years of age or older to register and subscribe to
              the Nwplus TV Service.
            </li>
            <li>
              Persons under the age of 18 years may use the Nwplus TV Service
              only under the supervision of their parents, guardians, or persons
              over the age of 18 years.
            </li>
          </ol>
        </li>
        <li>
          <b>Keeping Your Account Secure:</b> You are responsible for keeping
          your device secure in order to avoid breach, leading to unauthorized
          use and access to your account information.
        </li>
      </ol>

      <li id="licences" class="title">Licences</li>
      <ol type="a">
        <li>
          <b>Your Rights:</b> Nwplus TV does not claim ownership of the
          information that you submit for your Nwplus TV account.
        </li>
        <li>
          <b>Nwplus TV Rights:</b>You may not use any copyright, trademark (or
          any similar marks, domains, logos or other intellectual property
          unless you have express permission.
        </li>
        <li>
          <b>Your Licence to Nwplus TV:</b> In order to operate and provide our
          Services, you grant Nwplus TV a licence a worldwide, non-exclusive,
          royalty-free, sublicensable and transferable licence for the limited
          purpose of operating and providing our Services.
        </li>
      </ol>

      <li id="content-services" class="title">Content Services</li>
      <ol type="a">
        <li>
          <b>Subscription:</b> You are subscribed as an authorised user of
          Nwplus TV by either creating an account and logging in on the Nwplus
          TV free plan or the Nwplus TV subscription plan (per episode, weekly,
          or monthly).
        </li>
        <li>
          <b>Payment and Billing:</b> Content on the Nwplus TV platform will be
          accessible on your Nwplus TV account. The payment and billing shall be
          on your accounts page and shall provide:
          <ol type="i">
            <li>Your billing history;</li>
            <li>Your payment information;</li>
            <li>Your payment dates; and</li>
            <li>Any outstanding payment issues.</li>
          </ol>
          <br />
          <p>
            The billing date shall be automatically charged, depending on the
            chosen payment plan, either: weekly (after 7 days); or monthly (on
            the date of last payment). Given that the subscription is charged at
            the beginning of the billing cycle, the billing date may be one day
            earlier or one day later due to time zone differences.
          </p>
          <p>
            We shall not be responsible for any acts, failures, delays or
            omissions on the part of any third party, bank, payment aggregators
            or payment facilitators. You will be responsible for any acts,
            failures, delays or omission on the part of any third party, bank,
            payment aggregators or payment facilitators.
          </p>
          <p>
            Even if we suspend your subscription, you will be liable for all
            uncollected amounts which are due to us for the time that you
            continued to be a subscriber to Nwplus TV.
          </p>
        </li>
        <li>
          <b>On the Go:</b> If you wish to have offline entertainment, you can
          download TV shows, movies and podcasts on Nwplus TV and access them on
          your downloads page. In case of a change of longitudes or relocation,
          update account details accordingly.
        </li>
        <li>
          <b>Content Restrictions, Change to Content and Nwplus TV Service</b>
          <ol type="i">
            <li>
              It is your responsibility to ensure that prior to watching the
              content, the content meets your watching preferences. Watching and
              rating of content will activate Nwplus TV’s robust algorithm which
              will suggest content based on your content history and rating.
            </li>
            <li>
              We may continuously update the Nwplus TV Service, including the
              content. We reserve the right to make changes to the content as
              and when the situation so requires.
            </li>
          </ol>
        </li>
      </ol>

      <li id="reporting" class="title">
        Reporting Third-Party Copyright, Trademark and Other Intellectual
        Property Infringement
      </li>
      <ol type="a">
        <li>
          To report claims of third-party copyright, trademark or other
          intellectual property infringement, please visit our
          <a href="https://www.nwplus.biz/contact-us/">Contact Us</a>
          page. We shall take action in respect to serious or repeated
          infringement of intellectual property rights. Disabling or suspending
          an account will be in accordance with the
          <a routerLink="">Termination of Our Services</a>
          below.
        </li>
      </ol>

      <li id="liability" class="title">Limitation of Liability</li>
      <ol type="a">
        <li>
          Nwplus TV will not be liable to you for any lost profits or
          consequential, punitive, indirect or incidental damages relating to or
          arising out of your use of our services.
        </li>
      </ol>

      <li id="indemnification" class="title">Indemnification</li>
      <ol type="a">
        <li>
          If any person brings a claim (“Third Party Claim”) against us related
          to your actions on Nwplus TV, or any other use of our Services by you,
          you will, to the maximum extent permissible under the law, indemnify
          Nwplus TV and hold us harmless against all liabilities, losses and
          damages. You will cooperate fully as required by us in the defence or
          settlement of the claim.
        </li>
      </ol>

      <li id="dispute-resolution" class="title">Dispute Resolution</li>
      <ol type="a">
        <li>
          <b>Forum and Venue:</b> Any dispute, controversy or claim arising out
          of or in connection to this contract, or breach, termination or
          invalidity thereof shall be settled by arbitration in accordance with
          the Nairobi Centre for International Arbitration (NCIA) Arbitration
          Rules.
        </li>
        <li>
          <b>Governing Law:</b> The governing law shall be the law of the
          Republic of Kenya.
        </li>
      </ol>
      <li id="termination" class="title">Termination</li>
      <ol type="a">
        <li>
          Although we hope that Nwplus TV remains your go-to option in digital
          African content, you can terminate your relationship with Nwplus TV
          anytime, for any reason by deleting your account, or cancellation of
          subscription to Nwplus TV.
        </li>
        <li>
          We may temporarily suspend your Nwplus TV account on the basis of non-
          payment of the Nwplus TV subscription plans. Such suspension will
          continue until such a time that payment is made for access of our
          subscription packages.
        </li>
        <li>
          We reserve the right to modify, suspend or terminate your access to
          our Services anytime, such as for the violation of the Terms of
          Service or other harmful exposure on our part.
        </li>
      </ol>

      <li id="miscellaneous" class="title">Miscellaneous Provisions</li>
      <ol type="a">
        <li>
          <b>Assignment:</b> The terms, licences, obligations and rights granted
          under Nwplus TV Terms of Service may not be transferred, ceded,
          delegated or assigned by yourself.
        </li>
        <li>
          <b>Waiver:</b> A failure or delay by Nwplus TV to enforce its rights
          or any provision under these Terms of Service shall not be construed
          as a waiver of any right or remedy. A waiver under these Terms of
          Service is only effective when in writing and shall not be deemed to
          be a waiver of any subsequent breach.
        </li>
        <li>
          <b>Severability:</b>Each singular clause under these Terms of Service
          is independent and detachable from the others. As such, if any
          provision is declared by a judicial or competent authority, or
          arbitrator to be void, voidable, illegal or otherwise unenforceable,
          the provision shall be severed from these Terms of Service and the
          remaining provisions shall remain in full force and effect.
        </li>
        <li>
          <b>Headings of Terms of Service:</b>The headings used in these Terms
          of Service are for convenience purposes only and are not to be used to
          interpret the terms contained therein.
        </li>
        <li>
          <b>Entire Agreement:</b> These Nwplus TV Terms of Service make up the
          whole agreement and relationship between yourself and Nwplus TV. As
          such, no variation, extension or cancellation of these Terms of
          Service shall be binding, unless put down in writing by either
          yourself or Nwplus TV consensually.
        </li>
      </ol>
    </ol>
  </div>
</div>
