<div class="pay-container">

    <app-overlay *ngIf="isLoading" />
    <h1>PAYMENT PLANS</h1>
    <div class="cards">
        <div class="pay-card pay-daily" (click)="payOne()">
            <div class="content">
                <p class="content-title">Pay As You Go</p>
                <p>Unlimited access for 24 hours</p>
                <div class="price-background pay-daily">
                    <div class="price">Ksh 300</div>
                </div>
            </div>
        </div>

        <div class="pay-card pay-weekly" (click)="payTwo()">
            <div class="content">
                <p class="content-title">Weekly</p>
                <p>Unlimited access for one week</p>
                <div class="price-background pay-weekly">
                    <div class="price">Ksh 740</div>
                </div>
            </div>
        </div>
        <div class="pay-card pay-monthly" (click)="payThree()">
            <div class="content">
                <p class="content-title">Monthly</p>
                <p>Unlimited access for one month</p>
                <div class="price-background pay-monthly">
                    <div class="price">Ksh 1100</div>
                </div>
            </div>
        </div>
    </div>

    <div class="info">
        <p>You can cancel your subscription at any time. There are no
            cancellation fees or penalties, and your subscription will remain
            active until the end of the current billing period</p>
    </div>
</div>